import React, {useEffect, useState} from "react";
import {BHGInputWrapper, BHGInput} from "@bytehogs/design-system";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../../redux/actions/jobs";
import {connect} from "react-redux";

const initialState = {
    title: "",
    type: "Full time",
    location: "",
    currency: "GBP",
    minSalary: "",
    maxSalary: "",
    salaryTerm: "Per year",
    findersFee: "",
    aboutRole: "",
    whatToDo: "",
    lookingFor: "",
    aboutCompany: "",
    loading: false, chk1: false};

const PostJobForm = props => {
    const {companyDetails, jobsErr, jobSuccess, history, edit = false, jobDetails = null} = props;
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const {title,type,location, minSalary,maxSalary,salaryTerm, findersFee,aboutRole,whatToDo,lookingFor,aboutCompany, loading, chk1} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    if(!edit) {
                        await props.jobsActions.postJob({title,type,location, salaryFrom: minSalary, salaryTo: maxSalary,salaryTerm, findersFee,aboutRole,whatToDo,lookingFor,aboutCompany});
                    } else {
                        await props.jobsActions.updateJob({jobId: jobDetails.id,title,type,location, salaryFrom: minSalary, salaryTo: maxSalary,salaryTerm, findersFee,aboutRole,whatToDo,lookingFor,aboutCompany});
                        await props.jobsActions.getJobDetails(jobDetails.id);
                    }
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!title) theErrors.title = "Required";
            if (!location) theErrors.location = "Required";
            if (!minSalary) theErrors.minSalary = "Required";
            else if(isNaN(Number(minSalary))) theErrors.minSalary = "Numbers only";
            if (!maxSalary) theErrors.maxSalary = "Required";
            else if(isNaN(Number(maxSalary))) theErrors.maxSalary = "Numbers only";
            else if(Number(maxSalary) < Number(minSalary)) theErrors.maxSalary = "Must be higher than min"
            if (!salaryTerm) theErrors.salaryTerm = "Required";
            if (!findersFee) theErrors.findersFee = "Required";
            else if(isNaN(Number(findersFee))) theErrors.findersFee = "Numbers only";
            if (!aboutRole) theErrors.aboutRole = "Required";
            else if(aboutRole.length < 50) theErrors.aboutRole = "Minimum 50 characters"
            if (!whatToDo) theErrors.whatToDo = "Required";
            else if(whatToDo.length < 50) theErrors.whatToDo = "Minimum 50 characters"
            if (!lookingFor) theErrors.lookingFor = "Required";
            else if(lookingFor.length < 50) theErrors.lookingFor = "Minimum 50 characters"
            if (!aboutCompany) theErrors.aboutCompany = "Required";
            else if(aboutCompany.length < 50) theErrors.aboutCompany = "Minimum 50 characters"
            if(!chk1) theErrors.disclaimers = "You must agree to terms"
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    useEffect(() => {
        if(jobsErr && loading) {
            setValue('loading', false);
            setErrors({join: jobsErr});
        }
    }, [jobsErr]);

    useEffect(() => {
        if(jobSuccess && loading) {
            history.push('/my-jobs')
        }
    }, [jobSuccess]);

    useEffect(() => {
        if(!edit) {
            setState({...state, aboutCompany: companyDetails.bio, location: companyDetails.city})
        }
    }, [companyDetails]);

    useEffect(() => {
        if(edit && jobDetails) {
            setState({...state,
                title: jobDetails.title,
                type: jobDetails.type,
                location: jobDetails.location,
                currency: "GBP",
                minSalary: jobDetails.salaryFrom,
                maxSalary: jobDetails.salaryTo,
                salaryTerm: jobDetails.salaryTerm,
                findersFee: jobDetails.findersFee,
                aboutRole: jobDetails.aboutRole,
                whatToDo: jobDetails.whatToDo,
                lookingFor: jobDetails.lookingFor,
                aboutCompany: jobDetails.aboutCompany,
            })
        }
    }, []);

    const calculateRecommendedFee = () => {
        let recommendedFee = "";
        if(maxSalary && !isNaN(Number(maxSalary))) {
            let fee;
            if(salaryTerm === 'Per year') fee = Number(maxSalary) * 0.05;
            if(salaryTerm === 'Per day') fee = (Number(maxSalary) * 0.05) * 90;
            if(salaryTerm === 'Per hour') fee = (Number(maxSalary) * 0.05) * 70
            recommendedFee = "We recommend £" + Math.round(fee).toLocaleString()
        }
        return recommendedFee
    }

    const calculateServiceFee = () => {
        let serviceFee = "";
        if(findersFee && !isNaN(Number(findersFee))) {
            serviceFee = Math.round(Number(findersFee) * ((companyDetails.margin || 10)/100)).toLocaleString()
        }
        return serviceFee
    }

    return <div className="post-job-form">
        <div className="row">
            <div className="col-12">
                <label>Job title</label>
                <BHGInputWrapper error={errors.title}>
                    <BHGInput type="text" error={errors.title} value={title} onChange={e => setValue('title', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Job type</label>
                <div className="row job-type">
                    <div className="col-6 col-md-2">
                        <input id="ft" name="job-type" className="form-control" type="radio" checked={type === 'Full time'} onClick={() => setValue('type', 'Full time')}/>
                        <label htmlFor="ft">Full time</label>
                    </div>
                    <div className="col-6 col-md-2">
                        <input id="pt" name="job-type" className="form-control" type="radio" checked={type === 'Part time'} onClick={() => setValue('type', 'Part time')}/>
                        <label htmlFor="pt">Part time</label>
                    </div>
                    <div className="col-6 col-md-2">
                        <input id="ct" name="job-type" className="form-control" type="radio" checked={type === 'Contract'} onClick={() => setValue('type', 'Contract')}/>
                        <label htmlFor="ct">Contract</label>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <label>Job location</label>
                <BHGInputWrapper error={errors.location}>
                    <BHGInput type="text" error={errors.location} value={location} onChange={e => setValue('location', e.target.value)}/>
                </BHGInputWrapper>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Min Salary</label>
                <BHGInputWrapper error={errors.minSalary}>
                    <BHGInput type="text" min={0} error={errors.minSalary} value={minSalary} onChange={e => setValue('minSalary', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col">
                <label>Max Salary</label>
                <BHGInputWrapper error={errors.maxSalary}>
                    <BHGInput type="text" min={minSalary || 0} error={errors.maxSalary} value={maxSalary} onChange={e => setValue('maxSalary', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-md-3">
                <BHGInputWrapper error={errors.salaryTerm}>
                    <select className="form-control salary-term" value={salaryTerm} onChange={e => setValue('salaryTerm', e.target.value)}>
                        <option value="Per year">Per year</option>
                        <option value="Per day">Per day</option>
                        <option value="Per hour">Per hour</option>
                    </select>
                </BHGInputWrapper>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <label>Finders fee</label>
                <BHGInputWrapper error={errors.findersFee}>
                    <BHGInput type="text" error={errors.findersFee} value={findersFee} onChange={e => setValue('findersFee', e.target.value)}/>
                </BHGInputWrapper>
                <div className="recommended-fee">
                    {calculateRecommendedFee()}
                </div>
            </div>
            <div className="col-md-6">
                <label>Service charge</label>
                <BHGInputWrapper>
                    <input disabled className="form-control" value={calculateServiceFee()}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12"><hr/></div>

            <div className="col-12">
                <label>About the role</label>
                <BHGInputWrapper error={errors.aboutRole}>
                    <textarea rows={10} className={"form-control" + (errors.aboutRole ? " error" : "")} value={aboutRole} onChange={e => setValue('aboutRole', e.target.value)}></textarea>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>What you'll be doing</label>
                <BHGInputWrapper error={errors.whatToDo}>
                    <textarea rows={10} className={"form-control" + (errors.whatToDo ? " error" : "")} value={whatToDo} onChange={e => setValue('whatToDo', e.target.value)}></textarea>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Who we are looking for</label>
                <BHGInputWrapper error={errors.lookingFor}>
                    <textarea rows={10} className={"form-control" + (errors.lookingFor ? " error" : "")} value={lookingFor} onChange={e => setValue('lookingFor', e.target.value)}></textarea>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>About the company</label>
                <BHGInputWrapper error={errors.aboutCompany}>
                    <textarea rows={10} className={"form-control" + (errors.aboutCompany ? " error" : "")} value={aboutCompany} onChange={e => setValue('aboutCompany', e.target.value)}></textarea>
                </BHGInputWrapper>
            </div>
        </div>
        <div className="row disclaimers-container">
            <div className="col-10 disclaimers">
                <p>I acknowledge that I will have to pay the finders fee and the service charge if an offer is made to and accepted by any candidate found introduced via Coopful.</p>
            </div>
            <div className="col-2">
                <input type="checkbox" className="form-control" checked={chk1} onChange={() => setValue('chk1', !chk1)}/>
            </div>
        </div>
        {errors.disclaimers && <div className="form-error">{errors.disclaimers}</div>}
        {errors.join && <div className="form-error">{errors.join}</div>}
        <button className="btn btn-primary submit-button" onClick={handleSubmit}>{edit ? (loading ? "Updating..." : "Update Job") : (loading ? "Posting..." : "Post Job")}</button>
    </div>
}

const mapStateToProps = (state) => ({
    jobsErr: state.JobsReducer.jobsErr,
    jobSuccess: state.JobsReducer.jobSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJobForm);
