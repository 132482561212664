import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {Link} from "react-router-dom";

const RegisterContent = props => {
    const {changeTab, registerErr, loginErr, registerSuccess, authActions, profileActions, isAuthed, close = () => {}} = props;
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({firstName: "", lastName: "", email: "", password: "", loading: false, terms: false});
    const {firstName, lastName, email, password, loading, terms} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await authActions.register({name: firstName + ' ' + lastName, email, password });
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!terms) theErrors.join = "Please accept the terms & conditions";
            if (!firstName) theErrors.firstName = "Required";
            if (!lastName) theErrors.lastName = "Required";
            if (!email) theErrors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                theErrors.email = "Invalid email";
            if (!password) theErrors.password = "Required";
            else if (password.length < 6) theErrors.password = "Min length 6";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    useEffect(() => {
        if(registerErr || loginErr) {
            setValue('loading', false);
            setErrors({join: registerErr || loginErr});
        }
    }, [registerErr, loginErr]);

    useEffect(() => {
        if(registerSuccess) {
            const login = async () => {
                await authActions.login({email, password});
            }
            login();
        }
    }, [registerSuccess]);

    useEffect(() => {
        if(isAuthed) {
            const doNext = async () => {
                await profileActions.getCurrentUser();
                close();
            };
            doNext();
        }
    }, [isAuthed]);

    return <div>
        <h2>Create an Account</h2>
        <div className="row">
            <div className="col-md-6">
                <label>First name</label>
                <BHGInputWrapper error={errors.firstName}>
                    <BHGInput type="text" error={errors.firstName} value={firstName} onChange={e => setValue('firstName', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-md-6">
                <label>Last name</label>
                <BHGInputWrapper error={errors.lastName}>
                    <BHGInput type="text" error={errors.lastName} value={lastName} onChange={e => setValue('lastName', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Email</label>
                <BHGInputWrapper error={errors.email}>
                    <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Password</label>
                <BHGInputWrapper error={errors.password}>
                    <BHGInput type="password" error={errors.password} value={password} onChange={e => setValue('password', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12 text-right">
                <label className="mr-2">I agree to the <Link to="/terms" onClick={close}>terms & conditions</Link></label>
                <input type="checkbox" checked={terms} onClick={() => setValue('terms', !terms)}/>
            </div>
        </div>
        {errors.join && <div className="form-error">{errors.join}</div>}
        <div className="buttons">
            <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Creating..." : "Create Account"}</button>
            <button className="btn btn-link" onClick={() => changeTab('signIn')}>Already have an account? Sign In</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    registerErr: state.AuthReducer.registerErr,
    registerSuccess: state.AuthReducer.registerSuccess,
    loginErr: state.AuthReducer.loginErr,
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContent);
