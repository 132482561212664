import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";

const UpdatePasswordForm = props => {
    const {authActions, pwErr} = props;
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({password: "", newPassword: "", loading: false});
    const {password, newPassword, loading} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await authActions.updatePassword(password, newPassword);
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!password) theErrors.password = "Required";
            else if (password.length < 6) theErrors.password = "Min length 6";
            if (!newPassword) theErrors.newPassword = "Required";
            else if (newPassword.length < 6) theErrors.newPassword = "Min length 6";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    useEffect(() => {
        if(pwErr && loading) {
            setValue('loading', false);
            setErrors({join: pwErr});
        }
    }, [pwErr]);

    useEffect(() => {
        if(loading && !props.authLoading && !pwErr) {
            setState({password: "", newPassword: "", loading: false});
        }
    }, [props.authLoading]);

    return <>
        <div className="row">
            <div className="col-12">
                <label>Current Password</label>
                <BHGInputWrapper error={errors.password}>
                    <BHGInput type="password" error={errors.password} value={password} onChange={e => setValue('password', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>New Password</label>
                <BHGInputWrapper error={errors.newPassword}>
                    <BHGInput type="password" error={errors.newPassword} value={newPassword} onChange={e => setValue('newPassword', e.target.value)}/>
                </BHGInputWrapper>
            </div>
        </div>
        {errors.join && <div className="form-error">{errors.join}</div>}
        <button className="btn btn-primary" onClick={handleSubmit} >{loading ? "Updating..." : "Update"}</button>
    </>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    pwErr: state.AuthReducer.pwErr,
    authLoading: state.AuthReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
