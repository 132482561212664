import React, {useState} from "react"
import {Fade} from "react-reveal";
import {Link} from "react-router-dom";
import {Logo} from "./Logo";

export const CookieMessage = props => {
    const cookiesAccepted = document.cookie.replace(/(?:(?:^|.*;\s*)CoopfulCookiesAccepted\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    const [accepted, setAccepted] = useState(cookiesAccepted === 'true')
    const acceptCookies = () => {
        let expires = new Date()
        expires.setDate(expires.getDate() + 90) //expires in 90 days
        document.cookie = "CoopfulCookiesAccepted=true; expires=" + expires.toUTCString();
        setAccepted(true)
    }

    if(accepted) return null
    return <Fade bottom delay={500}>
        <div className="cookie-message">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 icon">
                        <Logo mobile dark text={false}/>
                    </div>
                    <div className="col-md-6">
                        <p>Just so you know, we use cookies to enhance your experience whilst visiting the Coopful website, you can read more about this <Link to="/privacy">here</Link></p>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-outline-primary" onClick={acceptCookies}>Got it</button>
                    </div>
                </div>
            </div>
        </div>
    </Fade>
}
