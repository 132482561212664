import React from "react";

export const HIWCard = props => {
    const {imgSrc, imgAlt, text} = props;
    return <div className="hiw-card">
        <div className="img-container">
            <img src={imgSrc} alt={imgAlt}/>
        </div>
        <p>{text}</p>
    </div>
}
