import React from "react";
import blogPosts from "../blog";
import {Link} from "react-router-dom";

export const RecentPosts = props => {
    return <section className="recent-posts">
        <h3>Recent Posts</h3>
        <div className="row">
            {blogPosts.slice(0).reverse().map((blog, index) => {
                let isActive = blog.slug === props.activeBlog
                const today = new Date()
                if(today.getTime() < new Date(blog.date).getTime()) isActive = true;
                if(index < 5 && !isActive) return <div key={blog.slug} className="col-12">
                    <Link to={"/blog/" + blog.category.toLowerCase() + "/" + blog.slug}>
                        <div className="blog-post">
                            <img src={blog.image} alt={blog.title}/>
                            <div>
                                <h4>{blog.title}</h4>
                                <p>{blog.description}...</p>
                                <span className="read-more">Read More</span>
                            </div>
                        </div>
                    </Link>
                </div>
                return null
            })}
        </div>
    </section>
}
