import React, {useRef, useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

const ProfileDropdown = props => {
    const {isAuthed, authActions, userDetails, history} = props;
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const openAuth = () => {
        authActions.openAuthModal('signIn');
    }

    const signOut = () => {
        history.push('/');
        authActions.logout();
        toggle();
    }

    useOnClickOutside(ref, () => open && toggle());

    return (
        <div className={"profile-dropdown" + (open ? " open" : "")} ref={ref}>
            <button onClick={isAuthed ? toggle : openAuth} className="btn btn-link">
                {isAuthed ? userDetails.name : "Sign In"}
            </button>
            <div className="menu">
                <nav>
                    <ul>
                        <li>
                            <Link to="/profile" onClick={toggle}>
                                My Details
                            </Link>
                        </li>
                        <li className="separator" />
                        <li>
                            <Link to="/submissions" onClick={toggle}>
                                Job Submissions
                            </Link>
                        </li>
                        <li>
                            <Link to="/payment-details" onClick={toggle}>
                                Payment Details
                            </Link>
                        </li>
                        <li className="separator" />
                        <li>
                            <Link to="/my-jobs" onClick={toggle}>
                                Job Postings
                            </Link>
                        </li>
                        <li>
                            <Link to="/company-details" onClick={toggle}>
                                Company Details
                            </Link>
                        </li>
                        <li className="separator" />
                        <li>
                            <a href="#" onClick={signOut}>
                                Sign out
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    userDetails: state.ProfileReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown));
