import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";

const ForgotPassword = props => {
    const {changeTab, authActions, pwErr, authLoading} = props;
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({loading: false, email: ""});
    const {loading, email} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await authActions.forgotPassword({email});
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    //setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!email) theErrors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                theErrors.email = "Invalid email";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    useEffect(() => {
        if(pwErr && loading) {
            setValue('loading', false);
            setErrors({join: pwErr});
        }
    }, [pwErr]);

    useEffect(() => {
        if(!authLoading && loading && !success && !pwErr) {
            setValue('loading', false);
            setSuccess(true);
        }
    }, [authLoading]);

    return <div>
        {success ? <>
            <h2>Check your Email</h2>
            <p>We've sent you an email with a password reset link.</p>
        </> :<>
            <h2>Forgot Password</h2>
            <div className="row">
                <div className="col-12">
                    <label>Email</label>
                    <BHGInputWrapper error={errors.email}>
                        <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
            </div>
            {errors.join && <div className="form-error">{errors.join}</div>}
            <div className="buttons">
                <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Sending..." : "Send Reset Email"}</button>
                <button className="btn btn-link" onClick={() => changeTab('signIn')}>Sign In</button>
            </div>
        </>}
    </div>
}

const mapStateToProps = (state) => ({
    pwErr: state.AuthReducer.pwErr,
    authLoading: state.AuthReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
