import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";

const SignInContent = props => {
    const {changeTab, authActions, profileActions, loginErr, isAuthed, close} = props;
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({loading: false, email: "", password: ""});
    const {loading, email, password} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await authActions.login({email, password });
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!email) theErrors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                theErrors.email = "Invalid email";
            if (!password) theErrors.password = "Required";
            else  if (password.length < 6) theErrors.password = "Min length 6";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    useEffect(() => {
        if(loginErr) {
            setValue('loading', false);
            setErrors({join: loginErr});
        }
    }, [loginErr]);



    useEffect(() => {
        if(isAuthed) {
            const doNext = async () => {
                await profileActions.getCurrentUser();
                close();
            };
            doNext();
        }
    }, [isAuthed]);

    return <div>
        <h2>Sign In</h2>
        <div className="row">
            <div className="col-12">
                <label>Email</label>
                <BHGInputWrapper error={errors.email}>
                    <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Password</label>
                <BHGInputWrapper error={errors.password}>
                    <BHGInput type="password" error={errors.password} value={password} onChange={e => setValue('password', e.target.value)}/>
                </BHGInputWrapper>
                <button className="btn btn-link forgot-pw" onClick={() => changeTab('forgot')}>Forgot Password?</button>
            </div>
        </div>
        {errors.join && <div className="form-error">{errors.join}</div>}
        <div className="buttons">
            <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Singing in..." : "Sign In"}</button>
            <button className="btn btn-link" onClick={() => changeTab('register')}>Create an Account</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    loginErr: state.AuthReducer.loginErr,
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInContent);
