import React, {useState} from "react";
import ReactPaginate from 'react-paginate';
import {CandidateModal} from "../modals/CandidateModal";
import {Link} from "react-router-dom";

export const ClosedPositionsTable = props => {
    const {resultsPerPage, data} = props;
    const [currentPage, setCurrentPage] = useState(0);
    let dataset = data.filter((x,i) => ((i >= resultsPerPage * currentPage) && (i < resultsPerPage * (currentPage + 1))) && x);
    if(dataset.length < resultsPerPage) {
        for(let i = dataset.length; i < resultsPerPage; i++) {
            dataset.push({})
        }
    }

    const handlePageClick = e => {
        setCurrentPage(e.selected);
    }

    return <div className="closed-positions-table">
        <div className="coop-table-head">
            <div className="row">
                <div className="col-md-3">Job title</div>
                <div className="col-md-3">Closed at</div>
                <div className="col-md-3">Status</div>
                <div className="col-md-3">Actions</div>
            </div>
        </div>
        <div className="coop-table-data">
        {dataset.map(position => {
            const hiredCandidate = position.referrals ? position.referrals.find(x => x.status === 4) : null;
            return <div className="row">
                {position.title && <>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-4 mobile-title">
                                Job title
                            </div>
                            <div className="col">
                                {position.title}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-4 mobile-title">
                                Closed at
                            </div>
                            <div className="col">
                                {new Date(position.closedAt).toLocaleString()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-4 mobile-title">
                                Status
                            </div>
                            <div className="col">
                                <div className="actions">
                                    <div className={"cus-btn " + (hiredCandidate ? "accept-btn" : "reject-btn")} title={hiredCandidate ? hiredCandidate.name : "Unfilled"}>{hiredCandidate ? "Position filled" : "Unfilled"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-4 mobile-title">
                                Actions
                            </div>
                            <div className="col">
                                <div className="actions">
                                    <Link className="cus-btn view-btn" to={"/view-jobs/" + position.id}>View</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        })}
        </div>
        <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={data.length/resultsPerPage || 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    </div>
}
