import React, {useEffect, useState} from "react";
import RecommendCandidateForm from "../components/Jobs/RecommendCandidateForm";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {connect} from "react-redux";
import {JobsActionCreators} from "../redux/actions/jobs";
import {SEO} from "@bytehogs/design-system";

const currencyList = {
    'GBP': '£'
}

const JobDetails = props => {
    const {match: {params: {id}}, isAuthed, jobDetails, userDetails} = props;
    const job = jobDetails[id];
    const [loaded, setLoaded] = useState(false);
    const [recommend, setRecommend] = useState(false);

    const getDetails = async () => {
        await props.jobsActions.getJobDetails(id);
        setLoaded(true)
    }

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(!job) {
            getDetails();
        } else {
            setLoaded(true);
        }
    }, []);

    const toggleRecommend = () => {
        if(isAuthed) {
            setRecommend(true)
        } else {
            props.authActions.openAuthModal('signIn');
        }
    }

    /*const downloadPDF = () => {
        const input = document.getElementById('job-post');
        html2canvas(input, {
            scale: 1,
            allowTaint: false,
            imageTimeout: 0,
            useCORS: true})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save(`coopful-${job.title.replace(' ', '-')}.pdf`);
            })
    }*/

    //SEO
    const {title, type, status, companyName, location, currency, salaryFrom, salaryTo, salaryTerm, findersFee,  aboutRole, aboutCompany, whatToDo, lookingFor, userId} = job || {};
    const salaryRange = currencyList[currency] + Number(salaryFrom).toLocaleString() + ' - ' + currencyList[currency] + Number(salaryTo).toLocaleString() + ' '  + (salaryTerm ? salaryTerm.toLowerCase() : '');
    return <div className="view job-details">
        {job && <SEO title={(status !== 1 ? "[CLOSED] " : "") + job.title + " in " + job.location + " " + salaryRange + " | Coopful"} slug={"/view-jobs/"+id} description={aboutRole.slice(0, 160)} image="" locale="en_GB" type="website" />}
        <div className="container"  id="job-post">
            {!loaded ? <div>Loading...</div> :  <>
                {job ? <div className="main-content">
                    <section className="intro">
                        <div className="name-bar">
                            <h1>{title}</h1>
                            <button className="btn btn-outline-primary download-btn" data-html2canvas-ignore="true" onClick={() => window.print()}>Download PDF</button>
                        </div>
                        <span className="location">{companyName}</span>
                        <span className="location">{location}</span>
                        <span className="location">{type}</span>
                        <span>{salaryRange}</span>
                        <div className={"earn " + (status !== 1 ? "closed" : "")} data-html2canvas-ignore="true">{status === 1 ? `Earn ${currencyList[currency] + Number(findersFee).toLocaleString()} finder's fee` : 'Job Closed'}</div>
                    </section>
                    <section className="details">
                        <h3>About the role</h3>
                        <pre>{aboutRole}</pre>
                        <h3>What you'll be doing</h3>
                        <pre>{whatToDo}</pre>
                        <h3>Who we are looking for</h3>
                        <pre>{lookingFor}</pre>
                        <h3>About the company</h3>
                        <pre>{aboutCompany}</pre>
                    </section>
                    {(userDetails.id !== userId)&&(status === 1) && <section data-html2canvas-ignore="true" className={"recommend " + (recommend ? "open" : "closed")}>
                        <button className="btn btn-primary open-recommend" onClick={toggleRecommend}>Recommend A Candidate</button>
                        <RecommendCandidateForm jobId={job.id}/>
                    </section>}
                </div> : <div><h1>Job Not Found</h1></div>}
            </>}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    jobDetails: state.JobsReducer.jobDetails,
    userDetails: state.ProfileReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
