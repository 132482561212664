import React, {useEffect, useState} from "react";
import {AuthModal} from "./AuthModal";
import {connect} from "react-redux";

const ModalWrapper = props => {
    const {modalType} = props;
    const [modal,setModal] = useState(null);

    useEffect(() => {
        if(modalType && modalType !== modal) {
            setModal(modalType);
        }
    }, [modalType]);

    return <>
        <AuthModal isOpen={modal} defaultModalType={modal} close={() => setModal(null)} />
    </>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    modalType: state.AuthReducer.modalType,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
