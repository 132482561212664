import React from "react";
import blogPosts from "../blog";
import {SEO} from "@bytehogs/design-system";

export const BlogPost = props => {
    const {match: {url}} = props
    window.scrollTo(0,0)
    let theUrl = url;
    const shareUrl = encodeURI('https://coopful.com' + theUrl);
    if(theUrl.endsWith("/")) {
        let temp = theUrl.split('')
        temp.splice(temp.length-1, 1)
        theUrl = temp.join('')
    }
    const BLOG = blogPosts.find(x => {if('/blog/' + x.category.toLowerCase() + '/' + x.slug.toLowerCase() === theUrl.toLowerCase()) return x})
    if(!BLOG) return <div>Not found </div>
    /*const {minutes, seconds} = regulateReadTime(calculateReadTime(BLOG.content))*/
    return <div className="view blog-post">
        <SEO
            title={BLOG.title + ' | Coopful'}
            description={BLOG.description}
            image={BLOG.image}
            slug={theUrl}
            type="article"
            fullImageUrl/>
        <section className="blog-content">
            <div className="container">
                <div className="blog-post">
                    <h1>{BLOG.title}</h1>

                    <img src={BLOG.image} className="feature-image" alt={BLOG.title}/>
                    <div dangerouslySetInnerHTML={{__html: BLOG.content}} className="raw-content"/>
                    <hr/>
                    <span className="author">Posted by {BLOG.author} on {new Date(BLOG.date).toDateString()}</span><br/>

                    <div className="social-shares">
                        <a className="resp-sharing-button__link"
                           href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl} target="_blank"
                           rel="noopener" aria-label="Share on LinkedIn">
                            <div
                                className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large">
                                <div aria-hidden="true"
                                     className="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/>
                                    </svg>
                                </div>
                                Share on LinkedIn
                            </div>
                        </a>
                        <a class="resp-sharing-button__link" href={"https://facebook.com/sharer/sharer.php?u=" + shareUrl} target="_blank" rel="noopener" aria-label="Share on Facebook">
                            <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
                            </div>Share on Facebook</div>
                        </a>
                        <a class="resp-sharing-button__link" href={"https://twitter.com/intent/tweet/?url=" + shareUrl} target="_blank" rel="noopener" aria-label="Share on Twitter">
                            <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
                            </div>Share on Twitter</div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        {/*
        //Dynamic CTA based on logged in or not
        <section className="cta-container">
            <div className="container">
                <h3>Can't wait to try us out?</h3>
                <p>We can't wait either - let's get started!</p>
                <Link to="register" className="btn btn-primary btn-rounded">Start 14 day free trial</Link>
            </div>
        </section>*/}
        {/*<LatestBlogPosts activeBlog={BLOG.slug}/>*/}
    </div>
}
