import React from "react";
import {connect} from "react-redux";
import {SEO} from "@bytehogs/design-system";

const PostJobUnauth = props => {
    const {userDetails: {name, email}} = props;

    return <div className="view post-job-unauth">
        <SEO title="Unverified Company | Coopful" slug="/post-job/unverified" description="Unverified" image="" locale="en_GB" type="website" />
        <div className="container">
            <h2>Before you can post</h2>
            <p>We are currently only accepting job postings following an initial screening call. Coopful is a new service and we want to ensure that all job postings are for legitimate vacancies in order to deliver a reliable service for candidates.</p>
            <p>We also want to use this opportunity to learn more about our potential customers in order to ensure we are continually developing features which align with our customer’s needs.</p>
            <p>Please use the link below to book a call with one of our representatives. Following the call your account will be enabled to post jobs without restriction.</p>
            <a href={`https://calendly.com/zeki-coopful/screening?name=${name.replace(' ', '%20')}&email=${email}`} target="_blank"><button className="btn btn-primary" rel="noreferrer">Book a Call</button></a>
        </div>
    </div>
}
const mapStateToProps = (state) => ({
    userDetails: state.ProfileReducer.userDetails
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJobUnauth);
