import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";

export const ViewCandidate = props => {
    const {changeTab,jobTitle, candidate: {name, email, phone, referrerName, recommendation, cvUrl}} = props;

    return <div>
        <div className="row details">
            <div className="col-md-7">
                <h2>{name}</h2>
                <div className="detail-info"><span className="font-weight-bold">Email:</span> {email}</div>
                <div className="detail-info"><span className="font-weight-bold">Phone:</span> {phone}</div>
            </div>
            <div className="col-md-5">
                {cvUrl && <a href={cvUrl} target="_blank" className="btn btn-outline-primary">Download CV</a>}
            </div>
        </div>
        <div className="recommended-by">
            Recommended by <span className="font-weight-bold">{referrerName}</span> for the position of <span className="font-weight-bold">{jobTitle}</span>
        </div>
        <h4>Letter of Recommendation</h4>
        <p className="recommendation">{recommendation}</p>
        <div className="buttons">
            <button className="btn btn-primary" onClick={() => changeTab('accept')}>Hire Candidate</button>
            <button className="btn btn-outline-primary" onClick={() => changeTab('reject')}>Reject Candidate</button>
        </div>
    </div>
}
