import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {SEO} from "@bytehogs/design-system";
import UpdateDetailsForm from "../components/profile/UpdateDetailsForm";
import UpdatePasswordForm from "../components/profile/UpdatePasswordForm";

const Profile = props => {
    const {isAuthed} = props;

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    if(!isAuthed) {
        props.authActions.openAuthModal('signIn');
    };

    return <div className="view company-details">
        <SEO title="My Details | Coopful" slug="/profile" description="Manage Profile Details" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>My Details</h2>
            <p>Manage your profile details.</p>
            <UpdateDetailsForm/>
            <h2>Update Password</h2>
            <UpdatePasswordForm/>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
