import { JobsActionTypes } from "../../types";
import {analytics, BytehogsAPI} from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";
import axios from "axios";

const SERVICE = "coop-mvp-be";

export const JobsActionCreators = {
    getDefaultJobs: (reset = false) => async (dispatch, getState) => {
        dispatch({
            type: JobsActionTypes.GET_DEFAULT_REQ,
        });
        try {
            const offset = getState().JobsReducer.jobs.length;
            let payload = {operation: "getDefault", offset};
            const response = await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.GET_DEFAULT_RES,
                error: null,
                payload: response.data,
                reset
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.GET_DEFAULT_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    search: (query, loadMore = false) => async (dispatch, getState) => {
        dispatch({
            type: JobsActionTypes.SEARCH_JOBS_REQ,
        });
        try {
            const offset = loadMore ? getState().JobsReducer.searchedJobs.length : 0;
            let payload = {operation: "search", query, offset};
            const response = await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.SEARCH_JOBS_RES,
                error: null,
                payload: response.data,
                reset: !loadMore
            });
        } catch (e) {
            if(e.status === 404) {
                dispatch({
                    type: JobsActionTypes.SEARCH_JOBS_RES,
                    error: null,
                    payload: {jobs: []},
                    reset: !loadMore
                });
            } else {
                dispatch({
                    type: JobsActionTypes.SEARCH_JOBS_RES,
                    error: e.data.message || e.message || "Something went wrong",
                });
            }
        }
    },
    getJobDetails: jobId => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.GET_JDETAILS_REQ,
        });
        try {
            let payload = {operation: "getJobDetails", jobId};
            const response = await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.GET_JDETAILS_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.GET_JDETAILS_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    submitReferral: (data, attachment) => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.SUB_REFER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "submitReferral", ...data};
            const response = await BytehogsAPI(SERVICE, "referrals", payload, STAGE);
            if(response.data.uploadURL) {
                await axios.put(response.data.uploadURL, attachment)
            }
            dispatch({
                type: JobsActionTypes.SUB_REFER_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.SUB_REFER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    postJob: (data) => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.POST_JOB_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "postJob", ...data};
            await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            analytics.event({
                category: 'jobs',
                action: 'post',
                label: 'post job',
                value: 0
            });
            dispatch({
                type: JobsActionTypes.POST_JOB_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.POST_JOB_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    updateJob: (data) => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.POST_JOB_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "updateJob", ...data};
            await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.POST_JOB_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.POST_JOB_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    getMyJobs: () => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.GET_MYJOBS_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getMyJobs"};
            const response = await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.GET_MYJOBS_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.GET_MYJOBS_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    getMyReferrals: () => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.GET_MYREFER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getMyReferrals"};
            const response = await BytehogsAPI(SERVICE, "referrals", payload, STAGE);
            dispatch({
                type: JobsActionTypes.GET_MYREFER_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.GET_MYREFER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    acceptReferral: referralId => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.UPDATE_REFER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, referralId, operation: "acceptReferral"};
            await BytehogsAPI(SERVICE, "referrals", payload, STAGE);
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    rejectReferral: (referralId, rejectionReason) => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.UPDATE_REFER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, referralId, rejectionReason, operation: "rejectReferral"};
            await BytehogsAPI(SERVICE, "referrals", payload, STAGE);
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    closePosition: (jobId, closeReason) => async (dispatch) => {
        dispatch({
            type: JobsActionTypes.UPDATE_REFER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, jobId, closeReason, operation: "closePosition"};
            await BytehogsAPI(SERVICE, "jobs", payload, STAGE);
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: null
            });
        } catch (e) {
            dispatch({
                type: JobsActionTypes.UPDATE_REFER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
};
