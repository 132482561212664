import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {connect} from "react-redux";
import {JobsActionCreators} from "../redux/actions/jobs";
import {MyReferralTable} from "../components/Jobs/MyReferralTable";
import {SEO} from "@bytehogs/design-system";

const Submissions = props => {
    const {isAuthed, referrals} = props;
    const openJobs = referrals.filter(x => x.jobStatus === 1);
    const closedJobs = referrals.filter(x => x.jobStatus !== 1);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        props.jobsActions.getMyReferrals();

        if(!isAuthed) {
            props.authActions.openAuthModal('signIn');
        };
    }, [])

    return <div className="view submissions">
        <SEO title="My Submissions | Coopful" slug="/submissions" description="Submissions" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>My Submissions</h2>
            <h4>Open Positions</h4>
            <MyReferralTable data={openJobs} resultsPerPage={5}/>
            <h4>Closed Positions</h4>
            <MyReferralTable data={closedJobs} resultsPerPage={5}/>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    referrals: state.JobsReducer.referrals,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);
