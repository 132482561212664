import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PostJobForm from "../components/Jobs/PostJobForm";
import {NoCompanyModal} from "../components/modals/NoCompanyModal";
import {SEO} from "@bytehogs/design-system";
import {JobsActionCreators} from "../redux/actions/jobs";

const EditJob = props => {
    const [loaded, setLoaded] = useState(false);
    const [comModal, setComModal] = useState(false);
    const {isAuthed, myJobs, jobDetails, userDetails, companyDetails, history, match: {params: {id}}} = props;
    let theJob = jobDetails[id] || {};
    if(theJob.userId !== userDetails.id) theJob = null;

    const getDetails = async () => {
        if(isAuthed) {
            await props.profileActions.getCompany();
            await props.jobsActions.getJobDetails(id);
        }
        setLoaded(true);
    }

    const openSignIn = () => props.authActions.openAuthModal('signIn');

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(!loaded) {
            getDetails();
        }
        if(!isAuthed) openSignIn();
    }, []);

    useEffect(() => {
        if(isAuthed && JSON.stringify(companyDetails) === '{}') {
            getDetails();
        }
    }, [isAuthed]);

    if(!loaded) return <div className="container">Loading...</div>;
    if(!theJob || theJob && theJob.status === 0) return <div className="view post-job min-vh-100">
        <div className="container">Job not found</div>
    </div>


    return <div className="view post-job">
        <SEO title="Edit Job on Coopful" slug="/edit-job" description="Edit a job on Coopful" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>

        <div className="container">
            <h2>Edit Job</h2>
            {!isAuthed ? <div><p>You need to sign in first.</p> <button className="btn btn-primary" onClick={openSignIn}>Sign In</button></div> : <div>
                <PostJobForm companyDetails={companyDetails} history={history} edit jobDetails={theJob}/>
            </div>}

        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    userDetails: state.ProfileReducer.userDetails,
    companyDetails: state.ProfileReducer.companyDetails,
    myJobs: state.JobsReducer.myJobs,
    jobDetails: state.JobsReducer.jobDetails,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditJob);
