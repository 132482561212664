import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, BrowserRouter, Redirect, Switch} from "react-router-dom";
import {analytics, AnalyticsTracker, BHGToast} from '@bytehogs/design-system';
import {ToastProvider} from 'react-toast-notifications';
import Header from "./Header";
import Home from "../../pages/Home";
import {About} from "../../pages/About";
import AllJobs from "../../pages/AllJobs";
import JobDetails from "../../pages/JobDetails";
import PostJobUnauth from "../../pages/PostJobUnauth";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {ProfileActionCreators} from "../../redux/actions/profile";
import PostJob from "../../pages/PostJob";
import CompanyDetails from "../../pages/CompanyDetails";
import PaymentDetails from "../../pages/PaymentDetails";
import ModalWrapper from "../modals/ModalWrapper";
import JobPostings from "../../pages/JobPostings";
import Submissions from "../../pages/Submissions";
import {Privacy} from "../../pages/Privacy";
import SetPassword from "../../pages/SetPassword";
import Profile from "../../pages/Profile";
import EditJob from "../../pages/EditJob";
import {CookieMessage} from "./CookieMessage";
import {Footer} from "./Footer";
import {BlogPost} from "../../pages/BlogPost";
import {Employers} from "../../pages/Employers";
import {Terms} from "../../pages/Terms";

const ProtectedRoute = ({ isVerified, li, ...props }) => {
    return isVerified ? (
        <Route {...props} />
    ) : (
        <Redirect to={li ? "/" : "/login?return_url=" + props.path} />
    );
};

class Routing extends Component {
    constructor(props) {
        super(props);
        analytics.initialize('UA-178510820-6', {});

        this.state = { canRender: false};
    }

    componentWillMount() {
        const { authActions, profileActions } = this.props;
        authActions.checkToken().then(() => profileActions.getCurrentUser()).finally(() => this.setState({ canRender: true }));
    }

    render() {
        const { canRender, modal } = this.state;
        const { isAuthed } = this.props;
        if (canRender) {
            return (
                <BrowserRouter>
                    <ToastProvider components={{ Toast: BHGToast }}>
                        <Header />
                        <Switch>
                            <Route exact path="/" component={AnalyticsTracker(Home)}/>
                            <Route exact path="/our-story" component={AnalyticsTracker(About)}/>
                            <Route exact path="/employers" component={AnalyticsTracker(Employers)}/>
                            <Route exact path="/view-jobs" component={AnalyticsTracker(AllJobs)}/>
                            <Route exact path="/view-jobs/:id" component={AnalyticsTracker(JobDetails)}/>
                            <Route exact path="/post-job" component={AnalyticsTracker(PostJob)}/>
                            <Route exact path="/post-job/unverified" component={AnalyticsTracker(PostJobUnauth)}/>
                            <Route exact path="/edit-job/:id" component={AnalyticsTracker(EditJob)}/>
                            <Route exact path="/company-details" component={AnalyticsTracker(CompanyDetails)}/>
                            <Route exact path="/payment-details" component={AnalyticsTracker(PaymentDetails)}/>
                            <Route exact path="/profile" component={AnalyticsTracker(Profile)}/>
                            <Route exact path="/my-jobs" component={AnalyticsTracker(JobPostings)}/>
                            <Route exact path="/submissions" component={AnalyticsTracker(Submissions)}/>
                            <Route exact path="/privacy" component={AnalyticsTracker(Privacy)}/>
                            <Route exact path="/terms" component={AnalyticsTracker(Terms)}/>
                            <Route exact path="/set-password/:token/:email" component={AnalyticsTracker(SetPassword)}/>
                            <Route path="/blog/:category/:slug" exact component={AnalyticsTracker(BlogPost)}/>
                            {/*<ProtectedRoute
                                isVerified={!isAuthed}
                                li
                                exact
                                path="/register"
                                component={AnalyticsTracker(Register)}
                            />*/}

                            <Redirect to="/" />
                        </Switch>
                        <ModalWrapper/>
                        <Footer />
                        <CookieMessage/>
                    </ToastProvider>
                </BrowserRouter>
            );
        }
        return <div className="container full-loader">
            <div className="loader-content">C<span className="d-none d-md-inline">oopful</span></div>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
