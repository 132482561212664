import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import SignInContent from "./SignInContent";
import RegisterContent from "./RegisterContent";
import ForgotPassword from "./ForgotPassword";

export const AuthModal = (props) => {
  const { isOpen, close, defaultModalType } = props;
  const [modalType, setModalType] = useState(defaultModalType);

  useEffect(() => {
    setModalType(defaultModalType);
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={"modal auth " + modalType}
      overlayClassName="modal-overlay"
      contentLabel="Auth Modal"
    >
      {{signIn: <SignInContent changeTab={setModalType} close={close}/>, register: <RegisterContent changeTab={setModalType} close={close}/>, forgot: <ForgotPassword changeTab={setModalType}/>}[modalType]}
    </Modal>
  );
}
