import React from "react";
import {Link} from "react-router-dom";

const currencyList = {
    'GBP': '£'
}

export const JobItem = props => {
    const {id, title, location, salaryFrom, salaryTo, salaryTerm, findersFee, currency} = props;
    return <Link to={"/view-jobs/"+id} className="job-item-link">
        <div className="job-item">
        <span className="sj-title">{title}</span>
        <span className="sj-location">{location}</span>
        <span className="sj-salary">{currencyList[currency] + Number(salaryFrom).toLocaleString()} - {currencyList[currency] + Number(salaryTo).toLocaleString() + ' ' + salaryTerm.toLowerCase()}</span>
        <div className="earn-container"><span className="sj-earn">Earn {currencyList[currency] + Number(findersFee).toLocaleString()}</span></div>
    </div>
    </Link>
}
