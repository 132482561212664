import React, {useState} from "react";
import RegisterContent from "./modals/RegisterContent";
import CompanyDetailsForm from "./company/CompanyDetailsForm";
import {withRouter} from "react-router-dom";

const EmployersOnboarding = props => {
    const {history} = props;
    const [stage, setStage] = useState(0);

    return <div className="employer-onboarding">
        <div className={"stage-row s-" + stage}>
            <div className="stage">
                <h3>Start Now</h3>
                <RegisterContent close={() => setStage(1)}/>
            </div>
            <div className="stage">
                <h3>Add Your Company</h3>
                <CompanyDetailsForm handleNewCompany={() => history.push('/post-job')}/>
            </div>
        </div>
    </div>
}

export default withRouter(EmployersOnboarding);
