import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper, SEO} from "@bytehogs/design-system";

const SetPassword = props => {
    const {authActions,profileActions, pwErr,pwSuccess, isAuthed, loginErr, history, match: {params: { token, email }}} = props;
    const [loggingIn, setLoggingIn] = useState(false);
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({password: "", pwConfirm: "", loading: false});
    const {password, pwConfirm, loading} = state;

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    //Potentially use the check token first?

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await authActions.setPassword({newPassword: password, token});
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!password) theErrors.password = "Required";
            else  if (password.length < 6) theErrors.password = "Min length 6";
            if (!pwConfirm) theErrors.pwConfirm = 'Required';
            if(password !== pwConfirm) theErrors.join = "Passwords should match";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };


    useEffect(() => {
        if(loading && (pwErr || loginErr)) {
            setValue('loading', false);
            setErrors({join: (pwErr || loginErr)});
        }
    }, [pwErr, loginErr]);

    useEffect(() => {
        if(loading && pwSuccess && !loggingIn) {
            setLoggingIn(true);
            //login
            authActions.login({email, password});
        }
    }, [pwSuccess]);

    useEffect(() => {
        if(isAuthed) {
            profileActions.getCurrentUser();
            history.push('/');
        }
    }, [isAuthed]);

    return <div className="view company-details">
        <SEO title="Set new Password | Coopful" slug="/set-password" description="" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>Reset your Password</h2>
            <div className="row">
                <div className="col-12">
                    <label>New Password</label>
                    <BHGInputWrapper error={errors.password}>
                        <BHGInput type="password" error={errors.password} value={password} onChange={e => setValue('password', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
                <div className="col-12">
                    <label>Confirm Password</label>
                    <BHGInputWrapper error={errors.pwConfirm}>
                        <BHGInput type="password" error={errors.pwConfirm} value={pwConfirm} onChange={e => setValue('pwConfirm', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
            </div>
            {errors.join && <div className="form-error">{errors.join}</div>}
            <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Confirming..." : "Confirm"}</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    loginErr: state.AuthReducer.loginErr,
    pwErr: state.AuthReducer.pwErr,
    pwSuccess: state.AuthReducer.pwSuccess,
    authLoading: state.AuthReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
