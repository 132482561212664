import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Logo} from "./Logo";
import {connect} from "react-redux";
import ProfileDropdown from "./ProfileDropdown";

const urlsToHide = ['/employers'];

const Header = props => {
    const {isAuthed, history} = props;

    console.log(history);
    const shouldHide = urlsToHide.includes(history.location.pathname)

    return (
        <header className={"main-header marketing-header"}>
            <div className="container">
                <div className="left">
                    <div className="brand">
                        <Link to="/" className="desktop">
                            <Logo />
                        </Link>
                        <Link to="/" className="mobile">
                            <Logo mobile />
                        </Link>
                    </div>
                </div>

                <nav className={"main-nav " +(shouldHide ? "hide" : "")}>
                    <Link to="/view-jobs">View all jobs</Link>
                    <Link to={isAuthed ? "/post-job" : "/employers"}>Post a job</Link>
                    <Link to="/#looking-to-hire">Pricing</Link>
                    <Link to="/our-story">Our Story</Link>
                </nav>

                <div className="header-actions">
                    <ProfileDropdown/>
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed
});

export default connect(mapStateToProps, null)(withRouter(Header));
