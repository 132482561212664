import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PostJobForm from "../components/Jobs/PostJobForm";
import {NoCompanyModal} from "../components/modals/NoCompanyModal";
import {SEO} from "@bytehogs/design-system";

const PostJob = props => {
    const [loaded, setLoaded] = useState(false);
    const [comModal, setComModal] = useState(false);
    const {isAuthed, companyDetails, history} = props;

    const getDetails = async () => {
        if(isAuthed) {
            await props.profileActions.getCompany();
        }
        setLoaded(true);
    }

    const openSignIn = () => props.authActions.openAuthModal('signIn');

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(!loaded) {
            getDetails();
        }
        if(!isAuthed) openSignIn();
    }, []);

    useEffect(() => {
        if(isAuthed && JSON.stringify(companyDetails) === '{}') {
            getDetails();
        }
    }, [isAuthed]);

    if(!loaded) return <div className="container">Loading...</div>

    if(!isAuthed) {

    } else if(JSON.stringify(companyDetails) === '{}') {
        //Can never close this modal...
        if(!comModal) setComModal(true);
        /*return <Redirect to="/company-details"/>*/
    } else if(companyDetails.verified !== 1) {
        return <Redirect to="/post-job/unverified"/>
    }

    return <div className="view post-job">
        <SEO title="Post a Job on Coopful" slug="/post-job" description="Post a job on Coopful" image="" locale="en_GB" type="website" />

        <div className="container">
            <h2>Post a Job</h2>
            {!isAuthed ? <div><p>You need to sign in first.</p> <button className="btn btn-primary" onClick={openSignIn}>Sign In</button></div> : <div>
                <PostJobForm companyDetails={companyDetails} history={history}/>
            </div>}
            <NoCompanyModal isOpen={comModal} close={() => setComModal(false)}/>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    companyDetails: state.ProfileReducer.companyDetails,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);
