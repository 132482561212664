import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {BHGInput, BHGInputWrapper, SEO} from "@bytehogs/design-system";

const PaymentDetails = props => {
    const {isAuthed, userDetails, profileActions, profileErr} = props;
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({bankAccName: "", accNumber: "", sortCode: "",loading: false});
    const {bankAccName, accNumber, sortCode, loading} = state;

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    if(!isAuthed) {
        props.authActions.openAuthModal('signIn');
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await profileActions.updateUser({metadata: JSON.stringify({...userDetails.metadata, bankAccName, accNumber, sortCode})});
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!bankAccName) theErrors.bankAccName = "Required";
            if (!accNumber) theErrors.accNumber = 'Required';
            else if(isNaN(Number(accNumber))) theErrors.accNumber = "Numbers only";
            else if(accNumber.length !== 8) theErrors.accNumber = "Must be 8 numbers";
            if (!sortCode) theErrors.sortCode = "Required";
            else if(isNaN(Number(sortCode))) theErrors.sortCode = "Numbers only";
            else if(sortCode.length !== 6) theErrors.sortCode = "Must be 6 numbers";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    const isSaveDisabled = () => {
        let isDisabled = false;
        const {metadata} = userDetails;
        const base = {
            bankAccName: metadata.bankAccName || "",
            accNumber: metadata.accNumber || "",
            sortCode: metadata.sortCode || "",
        }
        const local = {
            bankAccName,
            accNumber,
            sortCode
        }
        if(JSON.stringify(base) === JSON.stringify(local)) isDisabled = true;
        return isDisabled;
    };

    useEffect(() => {
        if(profileErr) {
            setValue('loading', false);
            setErrors({join: profileErr});
        }
    }, [profileErr]);

    useEffect(() => {
        if(loading && !props.profileLoading && !profileErr) {
            setValue('loading', false);
            profileActions.getCurrentUser();
        }
    }, [props.profileLoading]);

    useEffect(() => {
        if(JSON.stringify(userDetails) !== '{}') {
            const {metadata} = userDetails;
            setState({...state,
                bankAccName: metadata.bankAccName || "",
                accNumber: metadata.accNumber || "",
                sortCode: metadata.sortCode || "",
            });
        }
    }, [userDetails]);

    return <div className="view company-details">
        <SEO title="Manage Payment Details | Coopful" slug="/payment-details" description="Payment Details" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>Manage Payment Details</h2>
            <p>Add your payment details in order to for us to pay you any earned finder’s fees. Payments are made on a weekly basis on the earliest date after payment has been received from the hiring company.</p>
            <div className="row">
                <div className="col-12">
                    <label>Name on bank account</label>
                    <BHGInputWrapper error={errors.bankAccName}>
                        <BHGInput type="text" error={errors.bankAccName} value={bankAccName} onChange={e => setValue('bankAccName', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
                <div className="col-md-6">
                    <label>Account Number</label>
                    <BHGInputWrapper error={errors.accNumber}>
                        <BHGInput type="text" error={errors.accNumber} value={accNumber} onChange={e => setValue('accNumber', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
                <div className="col-md-6">
                    <label>Sort Code</label>
                    <BHGInputWrapper error={errors.sortCode}>
                        <BHGInput type="text" error={errors.sortCode} value={sortCode} onChange={e => setValue('sortCode', e.target.value)}/>
                    </BHGInputWrapper>
                </div>
            </div>
            {errors.join && <div className="form-error">{errors.join}</div>}
            <button className="btn btn-primary" onClick={handleSubmit} disabled={isSaveDisabled()}>{loading ? "Saving..." : "Save Details"}</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    userDetails: state.ProfileReducer.userDetails,
    profileErr: state.ProfileReducer.profileErr,
    profileLoading: state.ProfileReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
