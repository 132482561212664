import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {JobsActionCreators} from "../../redux/actions/jobs";

const AcceptCandidate = props => {
    const {jobsErr, jobSuccess, jobTitle, candidate: {id, name}, close} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = () => {
        if(!loading) {
            setLoading(true);
            setError(null);
            props.jobsActions.acceptReferral(id);
        }
    }

    useEffect(() => {
        if(loading && jobsErr) {
            setLoading(false);
            setError(jobsErr);
        }
    }, [jobsErr]);

    useEffect(() => {
        if(loading && jobSuccess) {
            props.jobsActions.getMyJobs();
            setLoading(false);
            close();
        }
    }, [jobSuccess]);

    return <div>
        <h2>Confirm Hire</h2>
        <p>Please confirm you have made an offer to <span className="font-weight-bold">{name}</span> for the position of <span className="font-weight-bold">{jobTitle}</span> which has been accepted.</p>
        <p> On confirmation you will receive an invoice for the finder’s fee and our service charge and the position will be marked as closed.</p>
        {error && <div className="form-error">{error}</div>}
        <div className="buttons">
            <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Confirming..." : "Confirm"}</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    jobsErr: state.JobsReducer.jobsErr,
    jobSuccess: state.JobsReducer.jobSuccess
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptCandidate);
