import React, {useEffect, useState} from "react";
import {JobList} from "../components/Jobs/JobList";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../redux/actions/jobs";
import {connect} from "react-redux";
import {SEO} from "@bytehogs/design-system";

const AllJobs = props => {
    const {jobs, totalJobs, searchedJobs, totalSearchJobs, location} = props;

    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    let theJobs = jobs || [];
    if(query.trim().length > 0) theJobs = searchedJobs || [];

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        const search = new URLSearchParams(location.search).get("search");
        handleTextChange({target: {value: search || ""}})
    }, []);

    const loadMore = () => {
        if(!loading) {
            setLoading(true);
            if (query.trim().length > 0) {
                props.jobsActions.search(query, true);
            } else {
                props.jobsActions.getDefaultJobs();
            }
        }
    }

    const handleTextChange = e => {
        const {target: {value}} = e;
        setQuery(value);
        if(value.trim().length > 0) {
            props.history.push({
                pathname: '/view-jobs',
                search: "?" + new URLSearchParams({search: value}).toString()
            })
            props.jobsActions.search(value);
        } else {
            props.history.push({
                pathname: '/view-jobs',
            });
            if(jobs.length === 0 || jobs.length < totalJobs) {
                props.jobsActions.getDefaultJobs();
            }

        }
    }

    const renderLoadMore = () => {
        let theTotal = query.trim().length > 0 ? totalSearchJobs : totalJobs;
        let ourJobs = query.trim().length > 0 ? searchedJobs : theJobs;

        if(ourJobs.length < theTotal) return <button onClick={loadMore} className="btn btn-outline-primary">{loading ? "Loading..." : "Load more"}</button>
    }

    useEffect(() => {
        setLoading(false);
    }, [jobs, searchedJobs]);


    return <div className="view all-jobs">
        <SEO title="View all open jobs on Coopful" slug="/view-jobs" description="Search through our open job positions and recommend friends to earn money." image="" locale="en_GB" type="website" />
        <div className="container">
            <section className="hero">
                <h1>Search Open Positions</h1>
                <div className="search-wrapper">
                    <input type="text" className="form-control" value={query} onChange={handleTextChange} placeholder="Search by title or location"/>
                </div>
            </section>
            <JobList jobs={theJobs}/>
            {renderLoadMore()}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    jobs: state.JobsReducer.jobs,
    totalJobs: state.JobsReducer.totalJobs,
    searchedJobs: state.JobsReducer.searchedJobs,
    totalSearchJobs: state.JobsReducer.totalSearchJobs,
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllJobs);
