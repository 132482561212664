import React, {useState} from "react";

export const SavingsCalculator = props => {
    const [salary, setSalary] = useState('50000');
    const [salaryType, setSalaryType] = useState('year');

    const handleSalaryChange = e => {
        let value = e.target.value;
        value = value.replace(',','');
        if(!isNaN(Number(value))) {
            setSalary(value);
        }
    }

    const salaryModifier = salary ? Number(salary).toLocaleString() : salary;

    const calculateAllFees = () => {
        let recruiterFee;
        let jobBoardFee;
        let coopfulFee;

        if(salaryType === 'year') {
            recruiterFee =  Number(salary) * 0.2;
            coopfulFee =  Number(salary) * 0.05;
        }
        if(salaryType === 'day') {
            recruiterFee =  Number(salary) * 0.2;
            coopfulFee =  (Number(salary) * 0.05) * 90;
        }
        if(salaryType === 'hour') {
            recruiterFee =  Number(salary) * 0.2;
            coopfulFee =  (Number(salary) * 0.05) * 70;
        }
        return {recruiterFee,jobBoardFee,coopfulFee}
    }

    const fees = calculateAllFees();

    return <section className="savings-calculator">
        <div className="info">
            <h3>How much could you save?</h3>
            <p>Enter your vacancy's salary info below.</p>
        </div>
        <div className="row">
            <div className="col-md-8">
                <div className="row mb-3">
                    <div className="col-md-5 salary-input">
                        <span>£</span>
                        <input type="text" className="form-control" placeholder="Salary " value={salaryModifier} onChange={handleSalaryChange}/>
                    </div>
                    <div className="col-md-7 salary-type-btns">
                        <button className={"btn " + (salaryType === 'year' ? 'btn-primary' : 'btn-outline-primary')} onClick={() => setSalaryType('year')}>Per year</button>
                        <button className={"btn " + (salaryType === 'day' ? 'btn-primary' : 'btn-outline-primary')} onClick={() => setSalaryType('day')}>Per day</button>
                        <button className={"btn " + (salaryType === 'hour' ? 'btn-primary' : 'btn-outline-primary')} onClick={() => setSalaryType('hour')}>Per hour</button>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <h5>Recruiter fees:</h5>
                <h6>£{Math.round(fees.recruiterFee).toLocaleString()} {salaryType === 'year' ? 'upon hire' : 'per ' + salaryType}</h6>
                <p>On average recruiters take 20% of the first year's salary.</p>
            </div>
            <div className="col-md-6">
                <h5>Coopful fees:</h5>
                <h6>£{Math.round(fees.coopfulFee).toLocaleString()} plus £{Math.round(fees.coopfulFee * 0.10).toLocaleString()} service fee upon hire</h6>
                <p>On average Coopful users offer a finders fee at around 5% of the first year's salary. But you can offer as much or as little as you like.</p>
            </div>
        </div>
    </section>
}
