import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";

const UpdateDetailsForm = props => {
    const {userDetails, profileActions, profileErr} = props;
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({name: "", email: "", loading: false});
    const {name, email, loading} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await profileActions.updateUser({name, email});
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!name) theErrors.name = "Required";
            if (!email) theErrors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                theErrors.email = "Invalid email";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    const isSaveDisabled = () => {
        let isDisabled = false;
        const base = {
            name: userDetails.name || "",
            email: userDetails.email || ""
        }
        const local = {
            name,
            email
        }
        if(JSON.stringify(base) === JSON.stringify(local)) isDisabled = true;
        return isDisabled;
    };

    useEffect(() => {
        if(profileErr) {
            setValue('loading', false);
            setErrors({join: profileErr});
        }
    }, [profileErr]);

    useEffect(() => {
        if(loading && !props.profileLoading && !profileErr) {
            setValue('loading', false);
            profileActions.getCurrentUser();
        }
    }, [props.profileLoading]);

    useEffect(() => {
        if(JSON.stringify(userDetails) !== '{}') {
            setState({...state,
                name: userDetails.name || "",
                email: userDetails.email || ""
            });
        }
    }, [userDetails]);

    return <>
        <div className="row">
            <div className="col-12">
                <label>Your Name</label>
                <BHGInputWrapper error={errors.name}>
                    <BHGInput type="text" error={errors.name} value={name} onChange={e => setValue('name', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Email Address</label>
                <BHGInputWrapper error={errors.email}>
                    <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)}/>
                </BHGInputWrapper>
            </div>
        </div>
        {errors.join && <div className="form-error">{errors.join}</div>}
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isSaveDisabled()}>{loading ? "Saving..." : "Save Details"}</button>
    </>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    userDetails: state.ProfileReducer.userDetails,
    profileErr: state.ProfileReducer.profileErr,
    profileLoading: state.ProfileReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDetailsForm);
