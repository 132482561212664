import React from "react";

export const ReasonCard = props => {
    const {step, title, text} = props;
    return <div className="reason-card">
        <div className="step">
            {step}
        </div>
        <h4>{title}</h4>
        <p>{text}</p>
    </div>
}
