import React, {useState} from "react";
import ReactPaginate from 'react-paginate';
import {CandidateModal} from "../modals/CandidateModal";

export const ReferralTable = props => {
    const {resultsPerPage, data, jobTitle} = props;
    const [currentPage, setCurrentPage] = useState(0);
    const [modal, setModal] = useState(null);
    const [candidate, setCandidate] = useState(null);
    let dataset = data.filter((x,i) => ((i >= resultsPerPage * currentPage) && (i < resultsPerPage * (currentPage + 1))) && x);
    if(dataset.length < resultsPerPage) {
        for(let i = dataset.length; i < resultsPerPage; i++) {
            dataset.push({})
        }
    }

    const handlePageClick = e => {
        setCurrentPage(e.selected);
    }

    const handleActionClick = (action, referral) => {
        setModal(action);
        setCandidate(referral)
    }

    return <div className="referral-table">
        <div className="coop-table-head">
            <div className="row">
                <div className="col-md-3">Name</div>
                <div className="col-md-3">Recommended By</div>
                <div className="col-md-6">Actions</div>
            </div>
        </div>
        <div className="coop-table-data">
        {dataset.map(referral => <div className="row">
            {referral.name && <>
            <div className="col-md-3">
                <div className="row">
                    <div className="col-4 mobile-title">
                        Name
                    </div>
                    <div className="col">
                        {referral.name}
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="row">
                    <div className="col-4 mobile-title">
                        Referrer
                    </div>
                    <div className="col">
                        {referral.referrerName}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-4 mobile-title">
                        Actions
                    </div>
                    <div className="col">
                        {referral.name && <div className="actions">
                            <button className="cus-btn view-btn" onClick={() => handleActionClick('view', referral)}>View</button>
                            <button className="cus-btn reject-btn" onClick={() => handleActionClick('reject', referral)}>Reject</button>
                            <button className="cus-btn accept-btn" onClick={() => handleActionClick('accept', referral)}>Accept</button>
                        </div>}
                    </div>
                </div>
            </div>
            </>}
        </div>)}
        </div>
        <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={data.length/resultsPerPage || 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
        <CandidateModal isOpen={modal} defaultModalType={modal} candidate={candidate || {}} close={() => handleActionClick(null, null)} jobTitle={jobTitle}/>
    </div>
}
