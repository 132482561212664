import { JobsActionTypes } from "../../types";

export const initialState = {
    loading: false,
    jobsErr: null,
    totalJobs: 0,
    jobs: [],
    searchedJobs: [],
    totalSearchJobs: 0,
    myJobs: [],
    jobDetails: {},
    referrals: [],
    jobSuccess: false,
};

export const JobsReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case JobsActionTypes.GET_DEFAULT_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
            };
        }
        case JobsActionTypes.GET_DEFAULT_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                jobs: action.reset ? payload.jobs : [...state.jobs, ...payload.jobs],
                totalJobs: payload.total_records
            };
        }
        case JobsActionTypes.SEARCH_JOBS_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
            };
        }
        case JobsActionTypes.SEARCH_JOBS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                searchedJobs: action.reset ? payload.jobs : [...state.searchedJobs, ...payload.jobs],
                totalSearchJobs: payload.total_records
            };
        }
        case JobsActionTypes.GET_JDETAILS_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
            };
        }
        case JobsActionTypes.GET_JDETAILS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                jobDetails: {
                    [payload.job.id]: payload.job
                }
            };
        }
        case JobsActionTypes.POST_JOB_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
                jobSuccess: false
            };
        }
        case JobsActionTypes.POST_JOB_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                jobSuccess: true
            };
        }case JobsActionTypes.GET_MYJOBS_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
            };
        }
        case JobsActionTypes.GET_MYJOBS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                myJobs: payload.jobs
            };
        }
        case JobsActionTypes.GET_MYREFER_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
            };
        }
        case JobsActionTypes.GET_MYREFER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                referrals: payload.referrals
            };
        }
        case JobsActionTypes.UPDATE_REFER_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
                jobSuccess: false
            };
        }
        case JobsActionTypes.UPDATE_REFER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                    jobSuccess: false
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                jobSuccess: true
            };
        }
        case JobsActionTypes.SUB_REFER_REQ: {
            return {
                ...state,
                loading: true,
                jobsErr: null,
                jobSuccess: false
            };
        }
        case JobsActionTypes.SUB_REFER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    jobsErr: error,
                    jobSuccess: false
                };
            }
            return {
                ...state,
                loading: false,
                jobsErr: null,
                jobSuccess: true
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
