import React from "react";
import {JobItem} from "./JobItem";
import {Link} from "react-router-dom";

export const JobList = props => {
    const {jobs, home} = props;
    return <section className="job-list">
        {home && <div className="info">
            <h3>Know someone who might be a good fit for one of these jobs?</h3>
            <p>Recommend them and earn the finders fee!</p>
        </div>}
        <div className="sj-list row">
            {jobs.map(job => <div className="col-md-6 col-lg-4" key={job.id}><JobItem {...job}/></div>)}
            {jobs.length === 0 && <div className="col-12 not-found">
                <p>No jobs found. (Yet!)</p>
            </div>}
        </div>
        {home && <Link to="/view-jobs" className="btn btn-primary">See all jobs</Link>}
    </section>
}
