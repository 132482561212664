import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {JobsActionCreators} from "../../redux/actions/jobs";

const RejectCandidate = props => {
    const {jobsErr, jobSuccess, jobTitle, candidate: {id, name}, close} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [rejectionReason, setRejection] = useState("");

    const handleSubmit = () => {
        if(!loading) {
            setLoading(true);
            setError(null);
            props.jobsActions.rejectReferral(id, rejectionReason);
        }
    }

    useEffect(() => {
        if(loading && jobsErr) {
            setLoading(false);
            setError(jobsErr);
        }
    }, [jobsErr]);

    useEffect(() => {
        if(loading && jobSuccess) {
            props.jobsActions.getMyJobs();
            setLoading(false);
            close();
        }
    }, [jobSuccess]);

    return <div>
        <h2>Reject Candidate</h2>
        <p>Confirm you would like to remove the candidate <span className="font-weight-bold">{name}</span> from consideration for the position of <span className="font-weight-bold">{jobTitle}</span>.</p>
        <p>You can optionally provide a reason for rejection which will be sent to referrer in order to improve future recommendations.</p>
        <label>Reason for rejection</label>
        <textarea className="form-control" rows={5} value={rejectionReason} onChange={e => setRejection(e.target.value)}></textarea>
        {error && <div className="form-error">{error}</div>}
        <div className="buttons">
            <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Confirming..." : "Confirm"}</button>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    jobsErr: state.JobsReducer.jobsErr,
    jobSuccess: state.JobsReducer.jobSuccess
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectCandidate);
