import React from "react";
import {Link} from "react-router-dom";

export const Footer = props => {
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <a href="https://www.linkedin.com/company/coopful"><i className="fab fa-linkedin-in"/> </a>
                    <a href="mailto:support@coopful.com">support@coopful.com</a>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/terms">Terms &amp; Conditions</Link>
                </div>
            </div>
        </div>
    </footer>
}
