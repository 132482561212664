import React, {useEffect} from "react";
import {SEO} from '@bytehogs/design-system'
import {JobList} from "../components/Jobs/JobList";
import {HIWCard} from "../components/HIWCard";
import {ReasonCard} from "../components/ReasonCard";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {JobsActionCreators} from "../redux/actions/jobs";
import {FAQSection} from "../components/FAQSection";
import {RecentPosts} from "../components/RecentBlogPosts";
import {SavingsCalculator} from "../components/SavingsCalculator";

const base_img_url = 'https://client-upload.bytehogs.com/coopful/';

const Home = props => {
    const {jobs,totalJobs, location: {hash}} = props;
    const theJobs = jobs ? jobs.filter((x,y) => y < 6 && x) : [];

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(jobs.length === 0 || jobs.length < totalJobs) {
            props.jobsActions.getDefaultJobs();
        }
    }, []);

    useEffect(() => {
        if(hash) {
            const elmnt = document.getElementById(hash.replace('#', ''));
            if(elmnt) elmnt.scrollIntoView();
        }
    }, [hash]);

    return <div className="view home">
        <SEO title="Earn money by referring your friends for jobs | Coopful" slug="" description="An affordable alternative to recruiters and job boards to efficiently fill open job positions." image="" locale="en_GB" type="website" />
        <div className="container">
            <section className="hero">
                <h1>Coopful</h1>
                <span className="definition">[coop-ful] <span className="noun">noun</span></span>
                <hr/>
                <p>1. A website where you can earn money by referring your friends for jobs.</p>
                <p>2. An affordable alternative to recruiters and job boards to efficiently fill open job positions.</p>
            </section>
            <JobList jobs={theJobs} home/>
            <section className="how-it-works">
                <div className="info">
                    <h3>How this works</h3>
                    <p>It's quite simple actually.</p>
                </div>
                <div className="hiw-grid row no-gutters">
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-1.svg"} imgAlt="IMG" text="Companies post a job"/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-2.svg"} imgAlt="IMG" text="and offer a finders fee."/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-3.svg"} imgAlt="IMG" text="You see the job"/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-4.svg"} imgAlt="IMG" text="and think of your friend."/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-5.svg"} imgAlt="IMG" text="You recommend them"/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-6.svg"} imgAlt="IMG" text="and the company checks them out."/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-7.svg"} imgAlt="IMG" text="They go for an interview"/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-8.svg"} imgAlt="IMG" text="and get an offer!"/>
                    </div>
                    <div className="col-md-4">
                        <HIWCard imgSrc={base_img_url + "media/hiw-9.svg"} imgAlt="IMG" text="Hey presto! You get the finders fee!"/>
                    </div>
                </div>
            </section>
            {/*<FAQSection/>*/}

            <section className="to-hire" id="looking-to-hire">
                <div className="info">
                    <h3>Looking to hire?</h3>
                    <p>Here are four reasons why you should try Coopful.</p>
                </div>
                <div className="reasons-list">
                    <ReasonCard step={1} title="You control the cost" text="You choose whatever finders fee you are willing to pay and we charge you that plus a 10% service fee."/>
                    <ReasonCard step={2} title="Pay nothing up front" text="You will only be charged once we have confirmed a successful hire has been made."/>
                    <ReasonCard step={3} title="No hire. No fee" text="We believe you should only pay for a service which delivers. There is no charge if no hire is made."/>
                    <ReasonCard step={4} title="Quality control" text="All recommendations are assessed to ensure they meet the job requirements."/>
                </div>
                <Link to="/post-job" className="btn btn-primary">Post a job</Link>
            </section>
            <SavingsCalculator/>
            <RecentPosts/>
            <section className="bottom-hero">
                <h3>Still not convinced?</h3>
                <p>Learn more about our mission and the inspiration behind cooperative recruitment</p>
                <Link to="/our-story" className="btn btn-primary">Our Story</Link>
            </section>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    jobs: state.JobsReducer.jobs,
    totalJobs: state.JobsReducer.totalJobs,
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
