import React, {useState, useEffect} from "react";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import {bindActionCreators} from "redux";
import {ProfileActionCreators} from "../../redux/actions/profile";
import {connect} from "react-redux";

const CompanyDetailsForm = props => {
    const {companyDetails, profileActions, profileErr, handleNewCompany = () => {}} = props;
    const [errors, setErrors] = useState({});
    const [existingCompany, setExistingCompany] = useState(false);
    const [state, setState] = useState({comName: "", website: "", city: "", bio: "", loading: false});
    const {comName, website, city, bio, loading} = state;


    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await profileActions.updateCompany({name: comName, website, city, bio});
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!comName) theErrors.comName = "Required";
            if (!website) theErrors.website = 'Required';
            else if (!/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(website)) theErrors.website = 'Invalid Website';
            if (!city) theErrors.city = "Required";
            if (!bio) theErrors.bio = "Required";
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    const isSaveDisabled = () => {
        let isDisabled = false;
        const base = {
            name: companyDetails.name,
            website: companyDetails.website,
            city: companyDetails.city,
            bio: companyDetails.bio
        }
        const local = {
            name: comName,
            website,
            city,
            bio
        }
        if(JSON.stringify(base) === JSON.stringify(local)) isDisabled = true;
        return isDisabled;
    };

    useEffect(() => {
        if(loading && profileErr) {
            setValue('loading', false);
            setErrors({join: profileErr});
        }
    }, [profileErr]);

    useEffect(() => {
        if(loading && !props.profileLoading && !profileErr) {
            setValue('loading', false);
            profileActions.getCompany();
            if(!existingCompany) {
                handleNewCompany();
            }
        }
    }, [props.profileLoading]);

    useEffect(() => {
        if(JSON.stringify(companyDetails) !== '{}') {
            setExistingCompany(true);
            setState({...state,
                comName: companyDetails.name || "",
                website: companyDetails.website || "",
                city: companyDetails.city || "",
                bio: companyDetails.bio || "",
            });
        }
    }, [companyDetails]);

    return <div className="company-details-form">
        <div className="row">
            <div className="col-12">
                <label>Company name</label>
                <BHGInputWrapper error={errors.comName}>
                    <BHGInput type="text" error={errors.comName} value={comName} onChange={e => setValue('comName', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Website</label>
                <BHGInputWrapper error={errors.website}>
                    <BHGInput type="text" error={errors.website} value={website} onChange={e => setValue('website', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>City</label>
                <BHGInputWrapper error={errors.city}>
                    <BHGInput type="text" error={errors.city} value={city} onChange={e => setValue('city', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>About the company</label>
                <BHGInputWrapper error={errors.bio}>
                    <textarea value={bio} onChange={e => setValue('bio', e.target.value)} className={"form-control" + (errors.bio ? " error" : "")} rows={4}></textarea>
                </BHGInputWrapper>
            </div>
        </div>
        {errors.join && <div className="form-error">{errors.join}</div>}
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isSaveDisabled()}>{loading ? "Saving..." : "Save Details"}</button>
    </div>
}


const mapStateToProps = (state) => ({
    companyDetails: state.ProfileReducer.companyDetails,
    profileErr: state.ProfileReducer.profileErr,
    profileLoading: state.ProfileReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsForm);
