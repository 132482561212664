import { AuthActionTypes } from "../../types";
import { BytehogsAPI, analytics } from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";

const SERVICE = "coop-mvp-be";

export const AuthActionCreators = {
    checkToken: () => async (dispatch) => {
        const sessionToken = await localStorage.getItem("sToken");
        const refreshToken = await localStorage.getItem("rToken");
        if (!sessionToken || !refreshToken) {
            dispatch({
                type: AuthActionTypes.AUTH_STATUS,
                error: "No Auth",
            });
        } else {
            try {
                await BytehogsAPI(SERVICE, "token", {
                    operation: "check",
                    sessionToken,
                    refreshToken,
                }, STAGE);
                dispatch({
                    type: AuthActionTypes.AUTH_STATUS,
                    error: null,
                });
            } catch (e) {
                dispatch({
                    type: AuthActionTypes.AUTH_STATUS,
                    error: "No Auth",
                });
            }
        }
    },
    register: (data) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.REGISTER_REQ,
        });
        try {
            await BytehogsAPI(SERVICE, "register", data, STAGE, null, true);
            analytics.event({
                category: 'account',
                action: 'register',
                label: 'register',
                value: 0
            });
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    login: (data) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.LOGIN_REQ,
        });
        try {
            const response = await BytehogsAPI(SERVICE, "login", data, STAGE, null, true);
            localStorage.setItem("sToken", response.data.sessionToken);
            localStorage.setItem("rToken", response.data.refreshToken);
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    forgotPassword: (data) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.FORGOT_PW_REQ,
        });
        try {
            let payload = { ...data, operation: "forgot" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.FORGOT_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.FORGOT_PW_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    checkPwToken: (data) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.SET_PW_REQ,
        });
        try {
            let payload = { ...data, operation: "checkToken" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    setPassword: (data) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.SET_PW_REQ,
        });
        try {
            let payload = { ...data, operation: "set" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    updatePassword: (currentPassword, newPassword) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.SET_PW_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, currentPassword, newPassword, operation: "update" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    openAuthModal: modalType => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.OPEN_AUTH,
            payload: modalType
        });
        setTimeout(() => {
            dispatch({
                type: AuthActionTypes.OPEN_AUTH,
                payload: null
            });
        }, 1000)
    },
    logout: () => async (dispatch) => {
        await localStorage.removeItem("sToken");
        await localStorage.removeItem("rToken");
        dispatch({
            type: "LOGOUT",
        });
    },
};
