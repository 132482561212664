import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {ViewCandidate} from "./ViewCandidate";
import AcceptCandidate from "./AcceptCandidate";
import RejectCandidate from "./RejectCandidate";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../../redux/actions/jobs";
import {connect} from "react-redux";

const defaultOptions = [
    "We are no longer hiring for this role",
    "We found a candidate through a job board",
    "We found a candidate through a recruiter"
]

const ClosePositionModal = (props) => {
  const { isOpen, close, jobId, jobTitle, jobsErr, jobSuccess } = props;
  const [closeReason, setCloseReason] = useState(null);
  const [other, setOther] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    const reason = closeReason ? closeReason.trim() : null;
    if(!reason) {
      setError("Reason is required");
    } else if(!loading) {
      setLoading(true);
      props.jobsActions.closePosition(jobId, closeReason);
    }
  }

  useEffect(() => {
    if(loading && jobsErr) {
      setLoading(false);
      setError(jobsErr);
    }
  }, [jobsErr]);

  useEffect(() => {
    if(loading && jobSuccess) {
      props.jobsActions.getMyJobs();
      setLoading(false);
      close();
    }
  }, [jobSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={"modal close-position "}
      overlayClassName="modal-overlay"
      contentLabel="Candidate Modal"
    >
      <div>
        <h2>Close Position</h2>
        <p>Please confirm you would like to close the position of <span className="font-weight-bold">{jobTitle}</span> without making a hire.</p>
        <p>You will no longer receive recommendations for this position.</p>
        <p>Please let us know why you are closing this position.</p>
        {defaultOptions.map((opt, i) => {
          const checked =(opt === closeReason) && !other;
          return <div className="row">
            <div className="col-1">
              <input type="radio" id={"opt-"+i} name="closeReason" value={opt} onChange={e => {setCloseReason(e.target.value);setOther(false)}} checked={checked}/>
            </div>
            <div className="col-11">
              <label className={checked ? "active" :""} htmlFor={"opt-"+i}>{opt}</label>
            </div>
          </div>
        })}
        <div className="row">
          <div className="col-1">
            <input type="radio" id={"opt-3"} name="closeReason" value="other" onChange={e => {setOther(true); setCloseReason("")}} checked={other}/>
          </div>
          <div className="col-11">
            <label className={other ? "active" :""} htmlFor={"opt-3"}>Other</label>
            <textarea className="form-control" rows={3} value={other ? closeReason : ""} onChange={e => setCloseReason(e.target.value)} disabled={!other}></textarea>
          </div>
        </div>
        {error && <div className="form-error">{error}</div>}
        <div className="buttons">
          <button className="btn btn-primary" onClick={handleSubmit}>{loading ? "Closing..." : "Close"}</button>
        </div>
      </div>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  jobsErr: state.JobsReducer.jobsErr,
  jobSuccess: state.JobsReducer.jobSuccess
});

const mapDispatchToProps = (dispatch) => ({
  jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosePositionModal);
