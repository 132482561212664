import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {ViewCandidate} from "./ViewCandidate";
import AcceptCandidate from "./AcceptCandidate";
import RejectCandidate from "./RejectCandidate";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../../redux/actions/jobs";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

export const NoCompanyModal = (props) => {
  const { isOpen, close } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={"modal no-company"}
      overlayClassName="modal-overlay"
      contentLabel="Candidate Modal"
    >
      <div>
        <h2>No Company Profile</h2>
        <p>To post jobs on Coopful you need a company profile. Click the button below to set up your company profile</p>

        <div className="buttons">
          <Link to="/company-details" className="btn btn-primary" >Company Details</Link>
        </div>
      </div>
    </Modal>
  );
}
