export const AuthActionTypes = {
    AUTH_STATUS: "AUTH_STATUS",
    REGISTER_REQ: "REGISTER_REQ",
    REGISTER_RES: "REGISTER_RES",
    LOGIN_REQ: "LOGIN_REQ",
    LOGIN_RES: "LOGIN_RES",
    FORGOT_PW_REQ: "FORGOT_PW_REQ",
    FORGOT_PW_RES: "FORGOT_PW_RES",
    SET_PW_REQ: "SET_PW_REQ",
    SET_PW_RES: "SET_PW_RES",
    OPEN_AUTH: "OPEN_AUTH",
}

export const ProfileActionTypes = {
    GET_USER_REQ: "GET_USER_REQ",
    GET_USER_RES: "GET_USER_RES",
    GET_COM_REQ: "GET_COM_REQ",
    GET_COM_RES: "GET_COM_RES",
    UPDATE_COM_REQ: "UPDATE_COM_REQ",
    UPDATE_COM_RES: "UPDATE_COM_RES",
}

export const JobsActionTypes = {
    GET_DEFAULT_REQ: "GET_DEFAULT_REQ",
    GET_DEFAULT_RES: "GET_DEFAULT_RES",
    SEARCH_JOBS_REQ: "SEARCH_JOBS_REQ",
    SEARCH_JOBS_RES: "SEARCH_JOBS_RES",
    GET_JDETAILS_REQ: "GET_JDETAILS_REQ",
    GET_JDETAILS_RES: "GET_JDETAILS_RES",
    SUB_REFER_REQ: "SUB_REFER_REQ",
    SUB_REFER_RES: "SUB_REFER_RES",
    GET_MYJOBS_REQ: "GET_MYJOBS_REQ",
    GET_MYJOBS_RES: "GET_MYJOBS_RES",
    GET_MYREFER_REQ: "GET_MYREFER_REQ",
    GET_MYREFER_RES: "GET_MYREFER_RES",
    UPDATE_REFER_REQ: "UPDATE_REFER_REQ",
    UPDATE_REFER_RES: "UPDATE_REFER_RES",
    POST_JOB_REQ: "POST_JOB_REQ",
    POST_JOB_RES: "POST_JOB_RES",
}
