import React, {useEffect, useState} from "react";
import {BHGInputWrapper, BHGInput} from "@bytehogs/design-system";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../../redux/actions/jobs";
import {connect} from "react-redux";
import {ReferralReceivedModal} from "../modals/ReferralReceivedModal";

const initialState = {firstName: "", lastName: "", email: "", phone: "", recommendation: "", cv: null, attachment: null,loading: false, chk1: false, chk2: false, chk3: false};

const RecommendCandidateForm = props => {
    const {jobId, jobsErr, jobSuccess} = props;
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const {firstName,lastName,email,phone,recommendation,cv,attachment, loading, chk1, chk2, chk3} = state;

    const setValue = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleCv = (files) => {
        const file = files[0];
        if(file) {
            setState({...state, cv: {
                name: file.name,
                fileType: file.type
            }, attachment: file});

        } else {
            setValue('cv', null)
        }
    }

    const handleSubmit = async () => {
        if (!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setValue('loading', false);
            else {
                try {
                    await props.jobsActions.submitReferral({jobId, name: firstName + ' ' + lastName, email, phone, recommendation, cv }, attachment);
                } catch (e) {
                    setErrors({join: e.message || "Sorry, something went wrong" });
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let theErrors = {};
            setErrors(theErrors);
            if (!firstName) theErrors.firstName = "Required";
            if (!lastName) theErrors.lastName = "Required";
            if (!email) theErrors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                theErrors.email = "Invalid email";
            if (!phone) theErrors.phone = "Required";
            else if (!/((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g.test(phone)) theErrors.phone = "Invalid phone";
            if (!recommendation) theErrors.recommendation = "Required";
            else if(recommendation.length < 50) theErrors.recommendation = "Minimum 50 characters"
            if(!chk1  || !chk2 || !chk3) theErrors.disclaimers = "You must agree to terms"
            if (Object.keys(theErrors).length > 0) setErrors(theErrors);
            return Object.keys(theErrors).length;
        }
    };

    const handleClose = () => {
        setModal(false);
        setState(initialState)
    }

    useEffect(() => {
        if(jobsErr && loading) {
            setValue('loading', false);
            setErrors({join: jobsErr});
        }
    }, [jobsErr]);

    useEffect(() => {
        if(jobSuccess && loading) {
            setValue('loading', false);
            setModal(true);
        }
    }, [jobSuccess]);

    return <div className="recommend-candidate-form">
        <h2>Recommend A Candidate</h2>
        <p>Only recommend a candidate if you have their express consent that they are interested in this position. You cannot recommend yourself for a position.</p>
        <div className="row">
            <div className="col-md-6">
                <label>First name</label>
                <BHGInputWrapper error={errors.firstName}>
                    <BHGInput type="text" error={errors.firstName} value={firstName} onChange={e => setValue('firstName', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-md-6">
                <label>Last name</label>
                <BHGInputWrapper error={errors.lastName}>
                    <BHGInput type="text" error={errors.lastName} value={lastName} onChange={e => setValue('lastName', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-12">
                <label>Email</label>
                <BHGInputWrapper error={errors.email}>
                    <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-md-6">
                <label>Phone</label>
                <BHGInputWrapper error={errors.phone}>
                    <BHGInput type="text" error={errors.phone} value={phone} onChange={e => setValue('phone', e.target.value)}/>
                </BHGInputWrapper>
            </div>
            <div className="col-md-6">
                <input
                    type="file"
                    id="cv-uploader"
                    onChange={(e) => handleCv(e.target.files)}
                    accept=".pdf,.doc,.docx"
                />
                <div className="cv-row">
                    {cv && <div>{cv.name}</div>}
                    <label htmlFor="cv-uploader" className="btn btn-outline-primary cv-button">Attach CV</label>
                </div>
            </div>
            <div className="col-12">
                <label>Letter of Recommendation</label>
                <BHGInputWrapper error={errors.recommendation}>
                    <textarea rows={10} className={"form-control" + (errors.recommendation ? " error" : "")} value={recommendation} onChange={e => setValue('recommendation', e.target.value)}></textarea>
                </BHGInputWrapper>
            </div>
        </div>
        <div className="row disclaimers-container">
            <div className="col-10 disclaimers">
                <p>I confirm that I have discussed this opportunity with the recommended candidate and have their permission to submit their details for this opportunity.</p>
            </div>
            <div className="col-2">
                <input type="checkbox" className="form-control" checked={chk1} onChange={() => setValue('chk1', !chk1)}/>
            </div>
            <div className="col-10 disclaimers">
                <p>To the best of my knowledge the candidate recommended for this role meets all the minimum criteria listed in the job specification.</p>
            </div>
            <div className="col-2">
                <input type="checkbox" className="form-control" checked={chk2} onChange={() => setValue('chk2', !chk2)}/>
            </div>
            <div className="col-10 disclaimers">
                <p>I understand that I will only receive the finders fee if my recommended candidate is hired and after the company has paid the finders fee.</p>
            </div>
            <div className="col-2">
                <input type="checkbox" className="form-control" checked={chk3} onChange={() => setValue('chk3', !chk3)}/>
            </div>
        </div>
        {errors.disclaimers && <div className="form-error">{errors.disclaimers}</div>}
        {errors.join && <div className="form-error">{errors.join}</div>}
        <button className="btn btn-primary submit-button" onClick={handleSubmit}>{loading ? "Submitting..." : "Submit Recommendation"}</button>
        <ReferralReceivedModal isOpen={modal} close={handleClose}/>
    </div>
}

const mapStateToProps = (state) => ({
    jobsErr: state.JobsReducer.jobsErr,
    jobSuccess: state.JobsReducer.jobSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendCandidateForm);
