import React, {useEffect} from "react";
import {SEO} from "@bytehogs/design-system";
import {RecentPosts} from "../components/RecentBlogPosts";
import {SavingsCalculator} from "../components/SavingsCalculator";
import EmployersOnboarding from "../components/EmployersOnboarding";

export const Employers = props => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    return <div className="view employers">
        <SEO title="Looking to hire? | Coopful" slug="/employers" description="Our mission is to establish the concept of cooperative recruitment and enable companies to build up a trusted network of individuals who they make their job positions available to." image="" locale="en_GB" type="website" />
        <div className="container">
            <section className="main-content">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Looking to hire?</h1>
                        <h5 className="sub">Get started on Coopful in 4 steps</h5>
                        <h6>1. Post a job</h6>
                        <p>Add your job spec to our site.</p>
                        <h6>2. Set a finder's fee</h6>
                        <p>Set a finder's fee of any amount you wish to pay a successful referrer.</p>
                        <h6>3. Receive referrals</h6>
                        <p>Using our referrer network, you'll start receiving candidates for your job.</p>
                        <h6>4. Interview & hire</h6>
                        <p>Interview candidates and hire the best match!</p>
                    </div>
                    <div className="col-md-6">
                        <EmployersOnboarding/>
                    </div>
                </div>
            </section>
            <SavingsCalculator/>
            <RecentPosts/>
        </div>
    </div>
}
