import { ProfileActionTypes } from "../../types";

export const initialState = {
    loading: false,
    profileErr: null,
    userDetails: {},
    companyDetails: {}
};

export const ProfileReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case ProfileActionTypes.GET_USER_REQ: {
            return {
                ...state,
                loading: true,
                profileErr: null,
            };
        }
        case ProfileActionTypes.GET_USER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    profileErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                profileErr: null,
                userDetails: payload.user
            };
        }
        case ProfileActionTypes.GET_COM_REQ: {
            return {
                ...state,
                loading: true,
                profileErr: null,
            };
        }
        case ProfileActionTypes.GET_COM_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    profileErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                profileErr: null,
                companyDetails: payload.company
            };
        }
        case ProfileActionTypes.UPDATE_COM_REQ: {
            return {
                ...state,
                loading: true,
                profileErr: null,
            };
        }
        case ProfileActionTypes.UPDATE_COM_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    profileErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                profileErr: null,
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
