import React, {useEffect} from "react";
import "./assets/sass/index.scss";
import Routing from "./components/global/Routing";
import {seo} from '@bytehogs/design-system'
import {Provider} from "react-redux";
import store from "./redux";

function App() {
  useEffect(() => {
    seo.initialize({url: 'https://www.coopful.com', creator: 'Coopful', siteName: 'Coopful'})
  },[]);

  return <div className="app light">
    <Provider store={store}>
      <Routing/>
    </Provider>
  </div>;
}

export default App;
