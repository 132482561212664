import React, {useEffect} from "react";
import {SEO} from "@bytehogs/design-system";

export const About = props => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    return <div className="view about template-1">
        <SEO title="Our Story | Coopful" slug="/our-story" description="Our mission is to establish the concept of cooperative recruitment and enable companies to build up a trusted network of individuals who they make their job positions available to." image="" locale="en_GB" type="website" />
        <div className="container">
            <section className="main-content">
                <h1>Our Story</h1>
                <div>

                    <p>We told people we were making a job board where you couldn’t apply for jobs – and I can understand why people thought we were crazy.</p>



                    <p>Let’s go back a few steps.</p>



                    <p>When you work in a startup you know how stressful hiring can be. Time and money are exceedingly precious (and in short supply) and any hire which you make needs to fit into a close knit family of existing staff.</p>



                    <p>There is nothing wrong with the existing channels companies use to make hires. Recruiters and Job Boards are great ways to fill specific types of roles but, to us at least, it felt like there should be another way.</p>



                    <p>A way which allowed companies to post a job, receive only vetted candidates, without having to pay upfront costs (like a job board) and without the sometimes unaffordable costs of a recruiter.</p>



                    <p>Thus was born Coopful.</p>



                    <p>We have put together a simple service which allows companies to post jobs and offer a finder’s fee to anyone who can make a recommendation that goes on to be hired by the company.</p>



                    <p>Sure one way to look at it is a job board with no Apply button but another way to look at it is that this is the first open marketplace for companies to connect with freelance recruiters.&nbsp; Through our platform, <em>anyone</em> can act as a recruiter for a company and companies can determine how much they want to pay for a hire.</p>



                    <p>We still act as a middleman (for now) and we screen all recommendations to ensure that any candidate recommended for a job still meets the role requirements but this is just the start of our journey.</p>



                    <p>Our mission is to establish the concept of <em>cooperative</em> <em>recruitment</em> and enable companies to build up a trusted network of individuals who they make their job positions available to. There is a lot in our future pipeline and we are just getting started.</p>



                    <p>If you want to become a part of our mission or are interested in hearing more drop us an email at support@coopful.com.</p>

                </div>
            </section>
        </div>
    </div>
}
