import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {ProfileActionCreators} from "../redux/actions/profile";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {BHGInput, BHGInputWrapper, SEO} from "@bytehogs/design-system";
import CompanyDetailsForm from "../components/company/CompanyDetailsForm";

const CompanyDetails = props => {
    const {isAuthed, profileActions, history} = props;

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        profileActions.getCompany();
    }, []);

    if(!isAuthed) {
        props.authActions.openAuthModal('signIn');
    };

    return <div className="view company-details">
        <SEO title="Manage Company Details | Coopful" slug="/company-details" description="Company Details" image="" locale="en_GB" type="website" >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>Company Details</h2>
            <p>We currently only accept job postings from within the UK.</p>
            <CompanyDetailsForm handleNewCompany={() => history.push('/post-job')}/>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    profileActions: bindActionCreators(ProfileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
