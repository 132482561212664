import { ProfileActionTypes } from "../../types";
import { BytehogsAPI } from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";

const SERVICE = "coop-mvp-be";

export const ProfileActionCreators = {
    getCurrentUser: () => async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.GET_USER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getCurrent"};
            const response = await BytehogsAPI(SERVICE, "user", payload, STAGE);
            dispatch({
                type: ProfileActionTypes.GET_USER_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: ProfileActionTypes.GET_USER_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    //TODO: change types etc
    updateUser: data => async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.UPDATE_COM_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {...data, sessionToken, operation: "update"}
            const response = await BytehogsAPI(SERVICE, 'user', payload, STAGE)
            dispatch({
                type: ProfileActionTypes.UPDATE_COM_RES,
                payload: response.data
            })
        } catch (e) {
            console.log("e", e)
            dispatch({
                type: ProfileActionTypes.UPDATE_COM_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    getCompany: () => async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.GET_COM_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getDetails"};
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: ProfileActionTypes.GET_COM_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: ProfileActionTypes.GET_COM_RES,
                error: e.data.message || e.message || "Something went wrong",
            });
        }
    },
    updateCompany: data => async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.UPDATE_COM_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {...data, sessionToken, operation: "update"}
            const response = await BytehogsAPI(SERVICE, 'company', payload, STAGE)
            dispatch({
                type: ProfileActionTypes.UPDATE_COM_RES,
                payload: response.data
            })
        } catch (e) {
            console.log("e", e)
            dispatch({
                type: ProfileActionTypes.UPDATE_COM_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
};
