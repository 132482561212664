import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {ViewCandidate} from "./ViewCandidate";
import AcceptCandidate from "./AcceptCandidate";
import RejectCandidate from "./RejectCandidate";

export const CandidateModal = (props) => {
  const { isOpen, close, defaultModalType, candidate, jobTitle } = props;
  const [modalType, setModalType] = useState(defaultModalType);

  useEffect(() => {
    setModalType(defaultModalType);
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={"modal candidate " + modalType}
      overlayClassName="modal-overlay"
      contentLabel="Candidate Modal"
    >
      {{view: <ViewCandidate changeTab={setModalType} candidate={candidate} jobTitle={jobTitle} close={close}/>,
        accept: <AcceptCandidate changeTab={setModalType} candidate={candidate} jobTitle={jobTitle} close={close}/>,
        reject: <RejectCandidate changeTab={setModalType} candidate={candidate} jobTitle={jobTitle} close={close}/>,}[modalType]}
    </Modal>
  );
}
