import React, {useState} from "react";
import ReactPaginate from 'react-paginate';

const currencyList = {
    'GBP': '£'
}

export const MyReferralTable = props => {
    const {resultsPerPage, data} = props;
    const [currentPage, setCurrentPage] = useState(0);
    let dataset = data.filter((x,i) => ((i >= resultsPerPage * currentPage) && (i < resultsPerPage * (currentPage + 1))) && x);
    if(dataset.length < resultsPerPage) {
        for(let i = dataset.length; i < resultsPerPage; i++) {
            dataset.push({})
        }
    }

    const handlePageClick = e => {
        setCurrentPage(e.selected);
    }

    const renderStatus = status => {
        if(status <= 3) return <div className="cus-btn view-btn">In consideration</div>
        if(status === 4) return <div className="cus-btn accept-btn">Accepted</div>
        if(status === 5) return <div className="cus-btn reject-btn">Rejected</div>
    }

    return <div className="referral-table">
        <div className="coop-table-head">
            <div className="row">
                <div className="col-md-3">Name</div>
                <div className="col-md-4">Position</div>
                <div className="col-md-2">Finders fee</div>
                <div className="col-md-3">Status</div>
            </div>
        </div>
        <div className="coop-table-data">
        {dataset.map(referral => {
            if(!referral.name) return <div className="row"/>
            return <div className="row">
            <div className="col-md-3">{referral.name}</div>
            <div className="col-md-4 job-position" title={referral.jobTitle}>{referral.jobTitle}</div>
            <div className="col-md-2">{currencyList[referral.currency] + Number(referral.findersFee).toLocaleString()}</div>
            <div className="col-md-3">{renderStatus(referral.status)}</div>
        </div>})}
        </div>
        <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={data.length/resultsPerPage || 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    </div>
}
