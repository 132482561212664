import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {ViewCandidate} from "./ViewCandidate";
import AcceptCandidate from "./AcceptCandidate";
import RejectCandidate from "./RejectCandidate";
import {bindActionCreators} from "redux";
import {JobsActionCreators} from "../../redux/actions/jobs";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

export const ReferralReceivedModal = (props) => {
  const { isOpen, close } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className={"modal referral-received"}
      overlayClassName="modal-overlay"
      contentLabel="Candidate Modal"
    >
      <div>
        <h2>Recommendation Received</h2>
        <p>Your submission will be reviewed and then forwarded to the hiring company. You will receive an email if your recommendation is made an offer.</p>
        <p>Please ensure your payment details are up to date.</p>
        <p>You can see the status of all your submissions on the <span className="font-weight-bold">My Submissions</span> page.</p>

        <div className="buttons">
          <Link to="/submissions" className="btn btn-primary" >My Submissions</Link>
          <Link to="/payment-details" className="btn btn-outline-primary" >Manage Payment Details</Link>
        </div>
      </div>
    </Modal>
  );
}
