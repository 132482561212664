import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../redux/actions/auth";
import {connect} from "react-redux";
import {ReferralTable} from "../components/Jobs/ReferralTable";
import {JobsActionCreators} from "../redux/actions/jobs";
import ClosePositionModal from "../components/modals/ClosePositionModal";
import {Link} from "react-router-dom";
import {ClosedPositionsTable} from "../components/Jobs/ClosedPositionsTable";
import {SEO} from "@bytehogs/design-system";

const JobPostings = props => {
    const {isAuthed, myJobs} = props;
    const [errors, setErrors] = useState({});
    const openJobs = myJobs.filter(x => x.status === 1);
    const closedJobs = myJobs.filter(x => x.status !== 1);
    const [activeOpen, setActiveOpen] = useState(0);
    const [close, setClose] = useState(false);
    const openJob = openJobs.length > 0 ? openJobs[activeOpen] : {};
    const {referrals, title, id, salaryFrom, salaryTo, salaryTerm, findersFee, serviceFee} = openJob;
    let salaryRange;
    if(id) {
        salaryRange = "£" + Number(salaryFrom).toLocaleString() + ' - ' + "£" + Number(salaryTo).toLocaleString() + ' ' + (salaryTerm ? salaryTerm.toLowerCase() : '');
    }

    if(!isAuthed) {
        props.authActions.openAuthModal('signIn');
    };

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        props.jobsActions.getMyJobs();
    }, []);

    const handleCloseModal = () => {
        if(activeOpen > 0) setActiveOpen(activeOpen - 1);
        setClose(false);
    }

    return <div className="view job-postings">
        <SEO title="My Jobs | Coopful" slug="/my-jobs" description="My jobs" image="" locale="en_GB" type="website"  >
            <meta name="robots" content="noindex"/>
        </SEO>
        <div className="container">
            <h2>My Open Positions</h2>
            {openJobs.length > 1 &&<div className="positions-list">
                    <div className="d-flex">
                    {openJobs.map((job, i) => <span key={job.id} className={activeOpen === i ? "active" : ""} onClick={() => setActiveOpen(i)}>{job.title}</span>)}
                    </div>
                </div>}
            <div className="job-top-bar">
                <h4>{title}</h4>
                {id && <div className="job-actions">
                    <Link to={"/view-jobs/"+id} className="btn btn-link">View</Link>
                    <Link to={"/edit-job/"+id} className="btn btn-link edit" onClick={() => setClose(true)}>Edit</Link>
                    <button className="btn btn-link remove" onClick={() => setClose(true)}>Close</button>
                </div>}
            </div>
            {id && <div className="more-details">
                <div><span>Salary:</span> {salaryRange}</div>
                <div><span>Finders Fee:</span> £{findersFee.toLocaleString()}</div>
                <div><span>Service Fee:</span> £{serviceFee.toLocaleString()}</div>
            </div> }
            <ReferralTable data={referrals || []} resultsPerPage={5} jobTitle={title}/>
            <h2>Closed Positions</h2>
            <ClosedPositionsTable data={closedJobs || []} resultsPerPage={5} />
        </div>
        <ClosePositionModal isOpen={close} close={handleCloseModal} jobId={id}/>
    </div>
}

const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
    myJobs: state.JobsReducer.myJobs,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
    jobsActions: bindActionCreators(JobsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobPostings);
